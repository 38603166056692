// @flow
import React from 'react'

const SearchStat = ({ query, page, perPage, total }: Object) => {
  const from = page * perPage + 1
  const to = Math.min((page + 1) * perPage, total)
  return (
    <div>
      Ergebnisse {from} - {to} von {total} für <strong>{query}</strong>
    </div>
  )
}

export default SearchStat
