// @flow

/* eslint-disable max-len */
import React from 'react'
import classNames from 'classnames'
import { applyStyling } from '../../../lib/styling'
import ContactForm from '../../ContactForm'
import Gallery from './../Gallery'
import Share from '../../Share'
import Device from '../../basic/Device/index'
import Collapsible from '../../basic/Collapsible'
import Button from '../../basic/Button/'
import { Link, injectIntl } from 'gatsby-plugin-intl'
import PetOwnerContact from '../PetOwnerContact'

import baseStyles from './Details.scss'
import messages from './messages'

type Props = {
  styles: Object,
  data: Object,
  types: Object,
  scrollToContact?: Function,
  isLostPet?: boolean,
  intl: { formatMessage: Function }
}

const Details = (props: Props) => {
  const { isLostPet, styles, data, scrollToContact, intl: { formatMessage } } = props
  const {
    name,
    description,
    gender,
    size,
    agePhase,
    photos,
    contact
  } = data

  const types = [
    ...(gender !== 'unknown' ? [
      { icon: `icon-gender-${gender}`,
        text: formatMessage(messages[gender])
      }
    ] : []),
    ...(size ? [
      { icon: `icon-size-${size}`,
        text: formatMessage(messages[`${size}Size`])
      }
    ] : []),
    { icon: `icon-${agePhase}`,
      text: formatMessage(messages[agePhase])
    }/* ,
    { icon: 'icon-clock-1',
      text: 'auf Zeit'
    }*/
  ]

  const DetailsComponent = () => (
    <>
      <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }}/>
      <ul className={styles.typeIconList}>
        {types.map((type, index) => (
          <li className={styles.typeIconItem} key={index}>
            <span className={classNames(styles.typeIcon, type.icon)} />
            {type.text}
          </li>
        ))}
      </ul>
      {isLostPet && contact &&
        <PetOwnerContact contact={contact} petName={name}/>
      }
      {!isLostPet &&
      <div>
        <div className={styles.priceInfo}>
          <h2 className={styles.priceInfoHeadline}>Wie geht es nun weiter</h2>
          <b>1.</b>
          Lernen Sie unsere Tiere kennen
        </div>
        <p className={styles.priceInfo}>
          <b>2.</b>
          Treten Sie mit uns in Kontakt, indem Sie uns anrufen oder eine E-Mail schreiben. Natürtlich können Sie uns auch gerne von Montag - Samstag  zwischen 12.00 und 16.00 Uhr (außer Dienstags) besuchen kommen.
        </p>
        <p className={styles.priceInfo}>
          <b>3.</b>
          Adoptieren Sie Ihr neues Haustier
        </p>
        <Link to="/tiervermittlung-kosten" className={styles.btnLink}>
          <Button color="link" tag="span" iconRight="icon-right" styles={styles.btn}>
            zur Preistabelle
          </Button>
        </Link>
        <p className={styles.priceQuote}>
          <strong>
            „Wir handeln im Sinne der Tiere“
          </strong>
          <strong>
            Monika Piasetzky
          </strong>
        </p>
      </div>}
    </>
  )

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.titleWrap}>
          <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: name }}/>
          <ul className={styles.typeList}>
            {types.map((type, index) => (
              <li className={styles.typeItem} key={index} dangerouslySetInnerHTML={{ __html: type.text }}/>
            ))}
          </ul>
        </div>

        <Device tablet desktop>
          <Share />
        </Device>
      </div>
      {photos && photos.length > 0 &&
      <Gallery
        title={name}
        photos={photos}
      />}

      <div className={styles.body}>
        <Device tablet desktop>
          <div className={styles.nav}>
            <div className={classNames(styles.navItem, styles['is-active'])}>
              <span className={classNames(styles.navIcon, 'icon-dogs')} />
              <span className={styles.navTitle}>Über mich</span>
            </div>
            <div className={styles.navItem} onClick={scrollToContact}>
              <span className={classNames(styles.navIcon, 'icon-user-1')} />
              <span className={styles.navTitle}>Kontakt</span>
            </div>
          </div>
          <DetailsComponent />
        </Device>
        <Device phone>
          <Collapsible
            styles={styles.collapsible}
            trigger={
              <>
                <span className={classNames(styles.collapsibleIcon, 'icon-dogs')}/>
                Über mich
              </>
            }
          >
            <DetailsComponent />
          </Collapsible>
          <Collapsible
            styles={styles.collapsible}
            isInitiallyOpen={false}
            trigger={
              <>
                <span className={classNames(styles.collapsibleIcon, 'icon-user-1')} />
                Kontakt
              </>
            }
          >
            <ContactForm title="Kontakt" />
          </Collapsible>
        </Device>
      </div>
    </div>
  )
}

export default injectIntl(applyStyling(baseStyles)(Details))
