// @flow

import React, { useState } from 'react'
import { applyStyling } from '../../lib/styling'
import BackToTop from '../BackToTop/index'
import { Container } from '../basic/Grid'
import ContactForm from '../ContactForm'
import HeroTeaser from '../HeroTeaser/index'
import MapTilerMap from 'components/basic/Map/MapTilerMap'
import { markerRenderer } from 'components/basic/Map/MapTilerMap/MapTilerMarkerRenderer'
import { localCache } from '../../lib/cache'
import AcceptCookies from '../../components/AcceptCookies'
import { COOKIE_NAME_MAPBOX, COOKIE_VALUE_ACCEPT } from 'components/AcceptCookies/hook'
import Button from '../../components/basic/Button'

import baseStyles from './ContactPage.scss'

type Props = {
  styles: Object,
  imageLandscape: string,
  imageLandscape2x: string,
  imageLandscape3x: string,
  imageLandscape4x: string,
  content: string,
  heroTeaserTitle: string,
  heroTeaserSubtitle: string,
  mapMarkers: Array<Object>
}

const ContactPage = (props: Props) => {
  const [ isMapCookiesAccepted, setIsMapCookiesAccepted ] = useState(
    localCache.get(COOKIE_NAME_MAPBOX) === COOKIE_VALUE_ACCEPT
  )

  const {
    styles,
    imageLandscape,
    imageLandscape2x,
    imageLandscape3x,
    imageLandscape4x,
    content,
    heroTeaserTitle,
    heroTeaserSubtitle,
    mapMarkers
  } = props
  return (
    <div className={styles.root}>
      <HeroTeaser
        imageLandscape={imageLandscape}
        imageLandscape2x={imageLandscape2x}
        imageLandscape3x={imageLandscape3x}
        imageLandscape4x={imageLandscape4x}
        title={heroTeaserTitle}
        subtitle={heroTeaserSubtitle}
        styles={styles.teaser}
      />

      <div className={styles.container}>
        <Container>
          <div dangerouslySetInnerHTML={{ __html: content }}/>

          {/* <Row>
            <Col md="6">
              <h3 className={styles.title}>Tierheim Düsseldorf</h3>
              <div className={styles.info}>
                Rüdigerstr. 1<br />
                40472 Düsseldorf
              </div>
              <div className={styles.info}>
                Tel.: <a
                  href="tel: 0211 - 65 18 50"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  0211 - 65 18 50
                </a><br />
                Fax: 0211 - 65 28 90<br />
                E-Mail: <a
                  href="mailto: info@tierheim-duesseldorf.de"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  info@tierheim-duesseldorf.de
                </a>
              </div>
              <div className={styles.info}>
                <h5 className={styles.infoTitle}>ACHTUNG !</h5>
                <p>
                  Bitte geben Sie bei Anfragen unbedingt Ihre Telefonnummer
                  mit an, für eine schnellere Bearbeitung.
                </p>
              </div>
            </Col>
            <Col md="6">
              <h3 className={styles.title}>TSV Düsseldorf und Umgebung e.V. 1873</h3>

              <div className={styles.info}>
                Fürstenwall 146<br />
                40217 Düsseldorf
              </div>
              <div className={styles.info}>
                Tel.: <a
                  href="tel: 0211 - 13 19 28"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                0211 - 13 19 28
                </a><br />
                Fax.: 0211 - 13 43 07<br />
                E-Mail: <a
                  href="mailto: info@tierschutzverein-duesseldorf.de"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                info@tierschutzverein-duesseldorf.de
                </a>
              </div>
              <div className={styles.info}>
                <h5 className={styles.infoTitle}>ACHTUNG !</h5>
                <p>
                  Bitte geben Sie bei Anfragen unbedingt Ihre Telefonnummer
                  mit an, für eine schnellere Bearbeitung.
                </p>
              </div>
            </Col>
          </Row>
          <h3 className={styles.title}>Öffnungszeiten</h3>
          <div className={styles.info}>
            Montag - Samstag: <span className={styles.infoValue}>12:00 - 16:00 Uhr</span>
          </div>
          <div className={styles.info}>
            Dienstags, Sonntags & Feiertage: <span className={styles.infoValue}>geschlossen</span>
          </div>*/}

          <ContactForm
            title="Haben Sie Fragen?"
            styles={styles.contactForm}
          />
        </Container>
        {mapMarkers && !isMapCookiesAccepted &&
          <AcceptCookies
            trigger
            triggerClass={styles.gdprButton}
            triggerLabel={<Button>zum Anzeigen der Karte akzeptieren Sie bitte Cookies gemäß DSGVO</Button>}
            onMapAccept={setIsMapCookiesAccepted}
          />
        }

        {mapMarkers && isMapCookiesAccepted && <MapTilerMap
          items={mapMarkers}
          markerRenderer={markerRenderer}
          width="100%"
          height={400}
          showFullscreen={false}
          className={styles.map}
        />}
      </div>
      <BackToTop />
    </div>
  )
}

export default applyStyling(baseStyles)(ContactPage)
