// @flow

/* eslint-disable max-len */
import React from 'react'
import { applyStyling } from '../../lib/styling'
import { Link } from 'gatsby-plugin-intl'
import { Container } from '../basic/Grid'
import Button from '../basic/Button'
import { withPageBySlug } from '../../provider/page'
import HeroTeaser from '../../components/HeroTeaser/index'

import baseStyles from './ErrorPage.scss'

type Props = {
  styles: Object,
  imageLandscape: string,
  imageLandscape2x: string,
  imageLandscape3x: string,
  imageLandscape4x: string,
  content: string,
  title: string,
  heroTeaserTitle: string,
  heroTeaserSubtitle: string
}

const ErrorPage = ({
  styles,
  content,
  imageLandscape,
  imageLandscape2x,
  imageLandscape3x,
  imageLandscape4x,
  heroTeaserTitle,
  heroTeaserSubtitle
}: Props) => (
  <div className={styles.root}>
    <HeroTeaser
      imageLandscape={imageLandscape}
      imageLandscape2x={imageLandscape2x}
      imageLandscape3x={imageLandscape3x}
      imageLandscape4x={imageLandscape4x}
      title={heroTeaserTitle}
      subtitle={heroTeaserSubtitle}
      styles={styles.teaser}
    />

    <Container className={styles.container}>
      <p className={styles.info} dangerouslySetInnerHTML={{ __html: content }}/>
      <Link to="/" className={styles.btnLink}>
        <Button tag="span" size="lg" iconRight="icon-right" styles={styles.btn}>
          zur Startseite
        </Button>
      </Link>
    </Container>
  </div>
)

export default withPageBySlug('404page')(applyStyling(baseStyles)(ErrorPage))
