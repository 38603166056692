import React, { useMemo } from 'react'
import Layout from '../components/Layout'
import Header from '../modules/Header'
import Footer from '../modules/Footer'
import ErrorPage from '../components/ErrorPage'
import SEO from '../components/seo'
import { withLocationProvider } from 'lib/locationContext'
import _get from 'lodash/get'

import Simple from './../templates/simple'
import ContactPage from '../components/ContactPage'
import NewLostPetPage from '../components/AnimalMediationPage/NewLostPetPage'
import NewMediationPetPage from '../components/AnimalMediationPage/NewMediationPetPage'
import NewPrivatePetPage from '../components/AnimalMediationPage/NewPrivatePetPage'
import TeamPage from '../components/TeamPage'
import NewsDetailsPage from '../components/NewsDetailsPage'
import NewsPage from '../components/NewsPage'
import SiteSearchResultsPage from '../components/SiteSearchResultsPage'
import LostPetsPage from '../components/AnimalMediationPage/LostPetsPage'
import PrivatePetsPage from '../components/AnimalMediationPage/PrivatePetsPage'
import TeoAnimalsPage from '../components/AnimalMediationPage/TeoAnimalsPage'
import { mapBasicPageProps, mapMapMarkers, withPageBySlug, mapAnimalDetailsPageProps } from '../provider/page'
import AnimalDetailsPage from '../components/AnimalDetailsPage'
import SitemapPage from '../components/SitemapPage'

const routePage = (location, page, isLoading) => {
  // if (isLoading) {
  //   return null
  // }
  if (isLoading) {
    return null
  }

  if (page && page.page.slug === 'sitemap') {
    return (
      <SitemapPage
        {...mapBasicPageProps(page)}
        location={location}
      />
    )
  }

  if (page && page.acf.template === 'site-search-results') {
    return (
      <SiteSearchResultsPage
        {...mapBasicPageProps(page)}
        location={location}
      />
    )
  }

  if (page && page.acf.template === 'animal-mediations') {
    return (
      <TeoAnimalsPage
        {...mapBasicPageProps(page)}
        location={location}
      />
    )
  }

  if (page && page.acf.template === 'news') {
    return (
      <NewsPage
        {...mapBasicPageProps(page)}
      />
    )
  }
  if (page && page.acf.template === 'lost-pets') {
    return (
      <LostPetsPage
        {...mapBasicPageProps(page)}
        location={location}
      />
    )
  }
  if (page && page.acf.template === 'private-pets') {
    return (
      <PrivatePetsPage
        {...mapBasicPageProps(page)}
        location={location}
      />
    )
  }
  if (page && [ 'lost_pet', 'private_pet', 'animal_data' ].indexOf(page.page.type) !== -1) {
    return (
      <AnimalDetailsPage
        {...mapBasicPageProps(page)}
        isLostPet={true}
        {...mapAnimalDetailsPageProps(page)}
      />
    )
  }
  if (page && [ 'news', 'articles', 'project' ].indexOf(page.page.type) !== -1) {
    return (
      <NewsDetailsPage
        page={page}
        {...mapBasicPageProps(page)}
        authorPhoto={page.acf['author_photo']}
        authorName={page.acf['author_name']}
        authorPosition={page.acf['author_position']}
        briefTitle={page.acf['brief_title']}
        briefDescription={page.acf['brief_description']}
        imageGallery={page.acf['image_gallery']}
        imageGalleryPosition={page.acf['image_gallery_position']}
        showShareLink={_get(page, 'acf.share_link')}
        date={page.date}
      />
    )
  }

  if (page && [ 'volunteers', 'team' ].indexOf(page.page.type) !==-1) {
    return (
      <NewsDetailsPage
        page={page}
        {...mapBasicPageProps(page)}
        authorPhoto={_get(page, 'page.featured_image_src_full')}
        authorName={_get(page, 'title')}
        authorPosition={page.acf['position']}
        briefTitle={_get(page, 'title')}
        briefDescription={_get(page, 'content')}
        imageGallery={page.acf['image_gallery']}
        imageGalleryPosition={page.acf['image_gallery_position']}
        showShareLink={_get(page, 'acf.share_link')}
        date={''}
        content={''}
      />
    )
  }

  if (page && ([ 'simple', 'payment' ].indexOf(page.acf.template) !== -1 || !page.acf.template)) {
    return (
      <Simple
        page={page}
        isLoading={isLoading}
        {...mapBasicPageProps(page)}
      />
    )
  }

  if (page && (page.acf.template === 'team' || page.acf.template === 'volunteers')) {
    return (
      <TeamPage
        page={page}
        isLoading={isLoading}
        {...mapBasicPageProps(page)}
      />
    )
  }

  if (page && (page.acf.template === 'contact')) {
    const mapMarkers = mapMapMarkers(_get(page, 'acf.map_markers'))
    return (
      <ContactPage
        page={page}
        isLoading={isLoading}
        mapMarkers={mapMarkers}
        {...mapBasicPageProps(page)}
      />
    )
  }

  if (page && (page.acf.template === 'new-lost-pet')) {
    return (
      <NewLostPetPage
        page={page}
        isLoading={isLoading}
        {...mapBasicPageProps(page)}
      />
    )
  }

  if (page && (page.acf.template === 'new-mediation-pet')) {
    return (
      <NewMediationPetPage
        page={page}
        isLoading={isLoading}
        {...mapBasicPageProps(page)}
      />
    )
  }

  if (page && (page.acf.template === 'new-private-pet')) {
    return (
      <NewPrivatePetPage
        page={page}
        isLoading={isLoading}
        {...mapBasicPageProps(page)}
      />
    )
  }

  if (page === null) {
    return (
      <ErrorPage
        page={page}
        isLoading={isLoading}
        {...mapBasicPageProps(page)}
      />
    )
  }
}

const getWithTeaser = (page: Object) => {
  if (!page) {
    return false
  }

  const basicProps = mapBasicPageProps(page)
  if (!basicProps.imageLandscape && !basicProps.heroTeaserTitle && !basicProps.heroTeaserSubtitle) {
    return false
  }
  return true
}

const NotFoundPage = ({ location, page, meta, isLoading }: Object) => {
  const is404 = !isLoading && !page
  const loadedPage = useMemo(() => {
    return routePage(location, page, isLoading )
  }, [ page, isLoading ])
  const withTeaser = useMemo(() => {
    return getWithTeaser(page)
  }, [ page, isLoading, is404 ])
  return (
    <Layout
      {...(is404 ? { isSecondary: true } : {})}
      footer={<Footer meta={meta}/>}
      location={location}
      isLoading={isLoading}
    >
      <SEO title={page ? page.title : (isLoading ? '' : '404: Not found')} />
      <Header withTeaser={withTeaser} meta={meta} isLoading={isLoading}/>
      {loadedPage}
    </Layout>
  )
}

export default withPageBySlug()(withLocationProvider(NotFoundPage))
