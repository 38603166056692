// @flow

/* eslint-disable max-len */
import React from 'react'
import { applyStyling } from '../../../lib/styling'
import HeroTeaser from '../../HeroTeaser/index'
import { Container } from '../../basic/Grid/index'
import BackBtn from '../../BackBtn/index'
import { Field, Form } from 'redux-form'
import MediationForm from './MediationForm/index'
import ImageUpload from '../../basic/Forms/ImageUpload/index'
import ContactForm from '../ContactForm/index'
import Button from '../../basic/Button/index'
import ReCaptchaFormRow from 'components/basic/Forms/ReCaptchaFormRow'
import BackToTop from 'components/BackToTop'

import baseStyles from './NewMediationPetPage.scss'

type Props = {
  isSubmitted: boolean,
  isLoading: boolean,
  hasError: boolean,
  styles: Object,
  images: Array<Object>,
  handleSubmit: Function,
  imageLandscape: string,
  imageLandscape2x: string,
  imageLandscape3x: string,
  imageLandscape4x: string,
  content: string,
  title: string,
  subtitle: string,
  heroTeaserTitle: string,
  heroTeaserSubtitle: string
}

const NewLostPetPage = ({
  isSubmitted,
  isLoading,
  hasError,
  styles,
  handleSubmit,
  images,
  imageLandscape,
  imageLandscape2x,
  imageLandscape3x,
  imageLandscape4x,
  heroTeaserTitle,
  heroTeaserSubtitle,
  title,
  content
}: Props) => (
  <div className={styles.root}>
    <HeroTeaser
      imageLandscape={imageLandscape}
      imageLandscape2x={imageLandscape2x}
      imageLandscape3x={imageLandscape3x}
      imageLandscape4x={imageLandscape4x}
      title={heroTeaserTitle}
      subtitle={heroTeaserSubtitle}
      styles={styles.teaser}
    />

    <div className={styles.container}>
      <Container>
        <div className={styles.toolbar}>
          <BackBtn />
        </div>

        <div className={styles.description}>
          <h3 className={styles.title}>{title}</h3>
          <div className={styles.info} dangerouslySetInnerHTML={{ __html: content }}/>
        </div>

        {isSubmitted ?
          <div>Thank you</div> :
          <Form onSubmit={handleSubmit}>
            <div className={styles.header}>
              <h3 className={styles.title}>1. Vermisstenformular</h3>
              <div className={styles.requiredLegend}>Pflichtfelder *</div>
            </div>
            <MediationForm />

            <div className={styles.header}>
              <h3 className={styles.title}>2. Bild hochladen</h3>
            </div>
            <div className={styles.info}>
              <p>Die Suche mit einem Bild zu erweitern steigert die Chance Ihr vermisstes Tier zu finden immens. Achten Sie darauf, dass es ein möglichst aktuelles Bild es. Außerdem sollte es möglichst scharf und in Farbe sein, um Verwechslungen auszuschließen.</p>
              <ul className={styles.list}>
                <li className={styles.itemList}>schnellere Identifizierung möglich</li>
                <li className={styles.itemList}>auffälligere Vermisstenanzeige, hebt sich mehr hervor</li>
                <li className={styles.itemList}>bleibt den Leuten besser im Gedächtnis</li>
              </ul>
            </div>

            <Field
              name="images"
              images={images}
              component={ImageUpload}
              styles={styles.imageUpload}
            />

            <div className={styles.header}>
              <h3 className={styles.title}>3. Ihre Kontaktdaten</h3>
              <div className={styles.requiredLegend}>Pflichtfelder *</div>
            </div>
            <ContactForm />
            <ReCaptchaFormRow/>

            <div className={styles.btnWrap}>
              <Button
                styles={styles.btn}
                type="submit"
                iconRight="icon-right"
                disabled={isLoading}
              >
                Abschicken
              </Button>
            </div>
            {hasError &&
            <div>Error</div>
            }
          </Form>
        }
      </Container>
    </div>
    <BackToTop/>
  </div>
)

export default applyStyling(baseStyles)(NewLostPetPage)
