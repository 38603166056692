// @flow

import { formValueSelector, reduxForm } from 'redux-form'
import { compose, withProps, withStateHandlers } from 'recompose'
import { scrollToInvalid } from '../../basic/Forms/Fields'

import NewMediationPetPage from './NewMediationPetPage'
import { connect } from 'react-redux'

const form = 'lost'

const EMPTY_ARRAY = []
const selector = formValueSelector(form)

export default compose(
  withStateHandlers(
    { isSubmitted: false, isLoading: false, hasError: false },
    {
      setIsSubmitted: () => isSubmitted => ({ isSubmitted }),
      setIsLoading: () => isLoading => ({ isLoading }),
      setHasError: () => hasError => ({ hasError })
    }
  ),
  connect(state => ({
    images: selector(state, 'images') || EMPTY_ARRAY
  })),
  withProps(() => ({
    initialValues: {
      images: [],
      castrated: 'unknown',
      vaccinated: 'unknown',
      chipped: 'unknown'
      // gender: 'female',
      // size: 'medium',
      // agePhase: 'senior'
    }
  })),
  reduxForm({
    form,
    onSubmit: async (values, dispatch, props) => {
      const { setIsSubmitted, setIsLoading, setHasError } = props
      try {
        /* setIsLoading(true)
        const formData = new FormData()
        Object.keys(values).forEach(key => {
          if (key === 'images') {
            for (let i = 0; i < values[key].length; i++) {
              formData.append(`images_${i}`, values[key][i])
            }
          } else {
            formData.append(key, values[key])
          }
        })
        const response = await fetch('/wp-json/wp/v2/new-lost-pet', {
          method: 'POST',
          body: formData
        })

        setIsLoading(false)
        if (response) {
          setIsSubmitted(true)
        }*/
        setIsSubmitted(true)
      } catch (e) {
        // console.log(e)
        setHasError(true)
        setIsLoading(false)
      }
    },
    onSubmitFail: scrollToInvalid
  })
)(NewMediationPetPage)

