// @flow
import { Container, Col } from '../../components/basic/Grid/'
import React from 'react'
import { applyStyling } from '../../lib/styling'
import Device from '../../components/basic/Device'
import baseStyles from './Notification.scss'
import classNames from 'classnames'

type Props = {
  styles: Object
}

const Notification = ({ styles }: Props) => (
  <Container>
    <Col lg="12">
      <Device tablet desktop>
        <div className={styles.notification}>
          <span className={classNames(styles.notificationIcon, 'icon-info')} />
          Nicht alle Tiere sind auf der Webseite vertreten
        </div>
      </Device>
    </Col>
  </Container>
)

export default applyStyling(baseStyles)(Notification)
