// @flow

/* eslint-disable max-len */
import React from 'react'
import { applyStyling } from '../../lib/styling'
import Carousel from '../basic/Carousel/index'

import baseStyles from './MobileCarousel.scss'

type Props = {
  styles: Object,
  images: Object,
  onClick?: Function
}

const MobileCarousel = ({ styles, images, onClick }: Props) => (
  <Carousel
    styles={styles.root}
    settings={{
      swipe: true,
      lazyLoad: 'progressive',
      infinite: true,
      dots: false,
      responsive: [
        {
          breakpoint: 9999,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            swipe: false
          }
        }
      ]
    }}
  >
    {images.map((image, index) => (
      <img src={image.src} className={styles.img} key={index} onClick={onClick} />
    ))}
  </Carousel>
)

export default applyStyling(baseStyles)(MobileCarousel)
