// @flow
import React from 'react'
import { compose } from 'recompose'
import { applyStyling } from '../../../../lib/styling'
import baseStyles from './SearchResultItem.scss'
import { Link } from 'gatsby-plugin-intl'
import _get from 'lodash/get'
import { shorten } from 'helpers/misc'

type Props = {
  styles: Object,
  item: Object
}

const SearchResultItem = (props: Props) => {
  const { styles, item } = props
  // const image = _get(item, 'featured_image_src_thumbnail')
  return (
    <div className={styles.root}>
      {/* {image &&*/}
      {/* <img className={styles.image} src={`http://wordpress-dev-td.lb.fcse.io/${image}`}/>}*/}
      <h2>
        <Link to={`/${item.slug}`}>
          {_get(item, 'title.rendered')}</Link>
      </h2>
      <div>
        {shorten(
          _get(item, 'acf.brief_description', '') ||
          _get(item, 'content.rendered', ''),
          500
        )}
      </div>
    </div>
  )
}

export default compose(
  applyStyling(baseStyles)
)(SearchResultItem)

