// @flow
import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import { applyStyling } from '../../../lib/styling'
import { compose } from 'recompose'

import messages from './messages'
import baseStyles from './PetOwnerContact.scss'

type Props = {
  styles: Object,
  petName: string,
  intl: { formatMessage: Function },
  contact: {
    email: string,
    mobile: string,
    phone: string,
    street: string,
  }
}

const PetOwnerContact = (props: Props) => {
  const { contact, styles, intl: { formatMessage }, petName } = props
  return (
    <div className={styles.root}>
      {contact.email &&
      <div>
        {formatMessage(messages.email)}: <a href={`mailto:${contact.email}?subject=${petName}`}>{contact.email}</a>
      </div>}
      {contact.mobile &&
      <div>{formatMessage(messages.mobile)}: <a href={`tel:${contact.mobile}`}>{contact.mobile}</a></div>
      }
      {contact.phone &&
      <div>{formatMessage(messages.phone)}: <a href={`tel:${contact.phone}`}>{contact.phone}</a></div>}
      {contact.street && <div>{formatMessage(messages.street)}: {contact.street}</div>}
    </div>
  )
}

export default compose(
  injectIntl,
  applyStyling(baseStyles)
)(PetOwnerContact)
