// @flow

import React from 'react'
import { applyStyling } from '../../lib/styling'
import Search from '../../components/Search'
import Device from '../../components/basic/Device'
import baseStyles from './Filtermask.scss'
import { GenericSelectField } from '../../components/basic/Forms/Fields'
import { Field } from '@fcse/param-converter'
import { PAGE_SIZE_OPTIONS } from '../../state/animalSearch'
import { compose } from 'recompose'
import { withLocation } from 'lib/locationContext'

type Props = {
  styles: Object,
  searchValues: Object,
  onQueryChange: Function,
  onSortByChange: Function,
  onPerPageChange: Function
}

const Filtermask = ({
  styles,
  onSortByChange,
  onQueryChange,
  onPerPageChange,
  searchValues
}: Props) => {
  const Sorting = () => (
    <div className={styles.sortingContainer}>
      <div className={styles.sortingLabel}>
        Sortieren nach:
      </div>
      <Field
        value={searchValues.sortBy}
        name="sortBy"
        component={GenericSelectField}
        options={[
          { value: 'new', label: 'Neuste' },
          { value: 'alphabet', label: 'Alphabet' }
        ]}
        onChange={onSortByChange}
        isSearchable={false}
        simpleValue
        styles={styles.sorting}
        hideSeparator
        onBlur={e => e.preventDefault()}
      />
    </div>
  )

  return (
    <div className={styles.root} id="filter-mask">
      <Device tablet desktop>
        <Search
          styles={styles.search}
          onQueryChange={onQueryChange}
          searchValues={searchValues}
        />
        <div className={styles.sortingWrap}>
          <Sorting/>
          <div className={styles.sortingContainer}>
            <div className={styles.sortingLabel}>
              Ergebnisse pro Seite:
            </div>

            <Field
              name="perPage"
              onChange={onPerPageChange}
              component={GenericSelectField}
              options={PAGE_SIZE_OPTIONS}
              isSearchable={false}
              simpleValue
              styles={styles.sorting}
              hideSeparator
              value={searchValues.perPage}
              onBlur={e => e.preventDefault()}
            />
          </div>
        </div>
      </Device>
      <Device phone>
        <Sorting />
      </Device>
    </div>
  )
}

export default compose(
  withLocation,
  applyStyling(baseStyles)
)(Filtermask)
