// @flow

import React from 'react'
import { applyStyling } from '../../../lib/styling'
import { Link } from 'gatsby-plugin-intl'
import classNames from 'classnames'

import defaultImage from '../../../images/illustration/zero-results.svg'

import baseStyles from './BaseItem.scss'

type Props = {
  styles: Object,
  image?: Object,
  title?: string,
  text?: string,
  link?: string,
  textLink?: string,
  imgOnClick?: Function,
  headerChildren?: Object
}

const BaseItem = ({
  styles, image, textLink, imgOnClick, link, title, text, headerChildren
}: Props) => {
  const TextContent = () => (
    <>
      <div className={styles.header}>
        <h3 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
        {headerChildren}
      </div>
      <div className={styles.text}>{text}</div>
    </>
  )

  const ItemContent = () => (
    <>
      <div className={classNames(styles.imageWrap, !image ? styles.noPhoto : '')} onClick={imgOnClick}>
        <img className={styles.image} src={image ? image : defaultImage}/>
      </div>
      {textLink ?
        <Link to={textLink} className={styles.textWrap}>
          <TextContent/>
        </Link>
        :
        <div className={styles.textWrap}>
          <TextContent/>
        </div>
      }
    </>
  )

  return (
    link ? <Link to={link} className={styles.root}>
      <ItemContent/>
    </Link>
      :
      <div className={styles.root}>
        <ItemContent/>
      </div>
  )
}

export default applyStyling(baseStyles)(BaseItem)
