// @flow

import React from 'react'
import { Field } from 'redux-form'
import required from '../../../validators/required'
import { applyStyling } from '../../../lib/styling'
import { InputField } from '../../basic/Forms/Fields'
import { Row, Col } from '../../basic/Grid/index'
import baseStyles from './ContactForm.scss'
import { createEmailValidator } from '../../../validators/email'

type Props = {
  styles: Object
}

const emailValidator = createEmailValidator('required')

const ContactForm = ({
  styles
}: Props) => (
  <div className={styles.root}>
    <Row>
      <Col md="4" className={styles.formGroup}>
        <Field
          name="contactName"
          component={InputField}
          placeholder="Vor und Nachname *"
          validate={[ required ]}
          required
        />
      </Col>
      <Col md="4" className={styles.formGroup}>
        <Field
          name="contactStreet"
          component={InputField}
          placeholder="Straße/Hausnummer *"
          validate={[ required ]}
          required
        />
      </Col>
      <Col md="4" className={styles.formGroup}>
        <Field
          name="contactZip"
          component={InputField}
          placeholder="PLZ/Ort *"
          validate={[ required ]}
          required
        />
      </Col>
    </Row>
    <Row>
      <Col md="4" className={styles.formGroup}>
        <Field
          name="contactEmail"
          component={InputField}
          placeholder="E-Mail Adresse *"
          validate={[ required, emailValidator ]}
          required
        />
      </Col>
      <Col md="4" className={styles.formGroup}>
        <Field
          name="contactMobile"
          component={InputField}
          placeholder="Handy"
        />
      </Col>
      <Col md="4" className={styles.formGroup}>
        <Field
          name="contactPhone"
          component={InputField}
          placeholder="Telefonnummer"
        />
      </Col>
    </Row>
  </div>
)

export default applyStyling(baseStyles)(ContactForm)
