// @flow

/* eslint-disable max-len */
import React, { useMemo, useCallback } from 'react'
import { applyStyling } from '../../lib/styling'
import Device from '../basic/Device'
import { Container, Row, Col } from '../../components/basic/Grid'
import HeroTeaser from '../../components/HeroTeaser'
import NewsFilterLayout from '../NewsFilterLayout'
import Search from '../Search'
import Teaser from '../../components/Teaser'
import Pagination from '../../components/basic/Pagination'
import { compose, withProps } from 'recompose'
import BaseItem from './../AnimalsList/BaseItem'
import _get from 'lodash/get'
import withQueryWrapper from '../../state/enchancers/newsSearch/withQueryWrapper'
import { withSearch } from '../../enhancers/withSearch'
import moment from 'moment'

import baseStyles from './NewsPage.scss'
import { shorten } from 'helpers/misc'
import { withNewsFacets, withPostList } from '../../provider/post'

type Props = {
  styles: Object,
  facets: Object,
  imageLandscape: string,
  imageLandscape2x: string,
  imageLandscape3x: string,
  imageLandscape4x: string,
  content: string,
  heroTeaserTitle: string,
  heroTeaserSubtitle: string,
  onPageChange: Function,
  newsSearch: Object,
  posts: Object
}

const mapFacets = (facets: Object, newsSearch: Object) => {
  return [
    {
      name: 'period',
      title: 'Artikel Archiv',
      options: _get(facets, 'years', []).map(v => ({
        value: v,
        label: v,
        icon: `icon-year-${v.toString().slice(2)}`
      })),
      value: newsSearch.period
    },
    {
      name: 'category',
      title: 'Kategorien',
      options: _get(facets, 'categories', []).map(v => ({
        value: v.id,
        label: v.name,
        icon: v['icon_class']
      })),
      value: newsSearch.category
    }
  ]
}

const NewsPage = ({
  onPageChange,
  styles,
  facets,
  imageLandscape,
  imageLandscape2x,
  imageLandscape3x,
  imageLandscape4x,
  heroTeaserTitle,
  heroTeaserSubtitle,
  newsSearch,
  posts
}: Props) => {
  const SearchComponent = withQueryWrapper(({ newsSearch, ...rest }: Object) => (
    <Search
      styles={styles.search}
      searchValues={newsSearch}
      targetPage="news"
      {...rest}
    />
  ))

  const filterItems = useMemo(() => {
    return mapFacets(facets, newsSearch)
  }, [ facets, newsSearch.period, newsSearch.category ])

  const getCategoryName = useCallback(categories => {
    const categoryFilter = filterItems.find(v => v.name === 'category')
    if (
      !categories ||
      categories.length === 0 ||
      _get(categoryFilter, 'options', []).length === 0) {
      return ''
    }

    const category = _get(categoryFilter, 'options', []).find(v => v.value === `${categories[0]}`)
    return category && category.label || ''
  }, [ filterItems ])

  return (
    <div className={styles.root}>
      <HeroTeaser
        imageLandscape={imageLandscape}
        imageLandscape2x={imageLandscape2x}
        imageLandscape3x={imageLandscape3x}
        imageLandscape4x={imageLandscape4x}
        title={heroTeaserTitle}
        subtitle={heroTeaserSubtitle}
        styles={styles.teaser}
      />

      <Device phone>
        <Container>
          <SearchComponent />
        </Container>
      </Device>

      <NewsFilterLayout
        styles={styles.layout}
        filterItems={filterItems}
      >
        <Row className={styles.row}>
          {posts && posts.result.map(item => (
            <Col md="6" lg="4" className={styles.col} key={item.id}>
              <Teaser
                styles={styles.teaser}
                link={`/${item.slug}`}
                image={item['featured_image_src_medium']}
                title={_get(item, 'title.rendered')}
                info={getCategoryName(item.categories)}
                date={moment(item.date).format('DD.MM.YYYY')}
                text={shorten(_get(item, 'acf.brief_description', '') || _get(item, 'content.rendered', ''), 100)}
              />
            </Col>
          ))}
          {posts && posts.total === 0 &&
            <BaseItem
              title="Keine Ergebnisse"
              text="Bitte passen Sie ihr Filter erneut an"
              styles={styles.emptyResults}
            />
          }
        </Row>
        {posts && <Device tablet>
          {matches => (
            <Pagination
              previousLabel="Zurück"
              nextLabel="Weiter"
              initialSelected={newsSearch.page || 0}
              results={posts.total}
              resultsPerPage={newsSearch.perPage}
              pageRangeDisplayed={matches ? 1 : 3}
              marginPagesDisplayed={1}
              styles={styles.pagination}
              onChange={onPageChange}
            />
          )}
        </Device>}
      </NewsFilterLayout>
    </div>
  )
}

export default compose(
  applyStyling(baseStyles),
  withNewsFacets,
  withSearch,
  withQueryWrapper,
  withProps(({ newsSearch }) => ({
    search: newsSearch
  })),
  withPostList([ 'news', 'articles' ])
)(NewsPage)
