// @flow
import React, { useCallback, useState, useRef } from 'react'
import useModalActions from '../../hooks/modalActions'
import AnimalGallery from '../../components/AnimalGallery/index'

type Props = {
  children: any
}

const PageGalleryWrapper = (props: Props) => {
  const { children } = props
  const { visible, closeModal, openModal } = useModalActions()
  const [ photos, setPhotos ] = useState([])
  const [ startIndex, setStartIndex ] = useState(0)
  const wrapperRef = useRef(null)

  const onClick = useCallback(e => {
    if (
      e.target.tagName === 'IMG' &&
      e.target.className.indexOf('wp-image-') !== -1 &&
      e.target.getAttribute('data-id')
    ) {
      let list = []
      const images = wrapperRef.current && wrapperRef.current.querySelectorAll('img[class^=wp-image-]') || []
      for (let i = 0; i < images.length; ++i) {
        list.push({
          className: images[i].className,
          original: images[i].getAttribute('data-full-url') || images[i].getAttribute('src'),
          thumbnail: images[i].getAttribute('src')
        })
      }
      setPhotos(list)

      const index = list.findIndex(image => image.className === e.target.className)
      setStartIndex(index !== -1 ? index : 0)
      openModal()
    }
  }, [ photos ])

  return (
    <div onClick={onClick} ref={wrapperRef}>
      {children}
      {visible && <AnimalGallery
        title={''}
        startIndex={startIndex}
        photos={photos}
        visible={true}
        closeModal={closeModal}
      />}
    </div>
  )
}

export default PageGalleryWrapper
