// @flow

import React, { useState, useCallback } from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import classNames from 'classnames'
import { applyStyling } from '../../../lib/styling'
import BaseItem from './../BaseItem'
import AnimalGallery from '../../AnimalGallery'
import { shorten } from 'helpers/misc'
import _ from 'lodash'

import messages from './messages'
import baseStyles from './AnimalItem.scss'

type Props = {
  styles: Object,
  title?: string,
  type?: string,
  gender?: string,
  size?: string,
  age?: string,
  agePhase?: string,
  breed?: string,
  color?: string,
  onTime?: string,
  description?: string,
  castrated?: string,
  vaccinated?: string,
  chipped?: string,
  chip?: string,
  onPlace?: string,
  link?: string,
  id?: number,
  photos: Array<Object>,
  image?: Object,
  intl: { formatMessage: Function }
}

const AnimalItem = (props: Props) => {
  const {
    styles, title, type, gender, size, age, agePhase, breed, onTime, description, link, photos,
    castrated, vaccinated, chipped, chip, onPlace, color,
    intl: { formatMessage }
  } = props
  const [ visible, setVisible ] = useState(false)
  const closeModal = useCallback(() => setVisible(false), [ setVisible ])
  const openModal = useCallback(() => {
    photos.length ? setVisible(true) : _.noop
  }, [ setVisible ])

  const normalizedDescription = shorten(description || '', 80)
  const mainImage = photos && photos.length ? photos[0] : ''
  return (
    <div className={styles.root}>
      <AnimalGallery
        title={title}
        photos={photos}
        visible={visible}
        closeModal={closeModal}
      />

      <BaseItem
        styles={styles.item}
        image={mainImage}
        title={title}
        imgOnClick={openModal}
        textLink={link}
        headerChildren={
          <ul className={styles.typeList}>
            {gender &&
            <li className={styles.typeItem}>
              <span className={classNames(styles.typeIcon, `icon-gender-${gender}`)} />
            </li>}
            {size &&
            <li className={styles.typeItem}>
              <span className={classNames(styles.typeIcon, `icon-size-${size}`)} />
            </li>}
            {agePhase &&
            <li className={styles.typeItem}>
              <span className={classNames(styles.typeIcon, `icon-${agePhase}`)} />
            </li>}
            <li className={styles.typeItem}>
              <span className={classNames(styles.typeIcon, 'icon-endless')} />
            </li>
          </ul>
        }
        text={
          <>
            <dl className={styles.infoList}>
              {type &&
              <>
                <dt className={classNames(styles.infoKey)}>Tierart:</dt>
                <dd className={styles.infoValue}>{formatMessage(messages[type])}</dd>
              </>
              }

              {gender &&
              <>
                <dt className={classNames(styles.infoKey)}>Geschlecht:</dt>
                <dd className={styles.infoValue}>{formatMessage(messages[gender])}</dd>
              </>
              }

              {size &&
              <>
                <dt className={classNames(styles.infoKey, !size && styles['is-hidden'])}>Größe:</dt>
                <dd className={styles.infoValue}>{formatMessage(messages[`${size}Size`])}</dd>
              </>
              }

              {age &&
              <>
                <dt className={classNames(styles.infoKey, !age && styles['is-hidden'])}>Alter:</dt>
                <dd className={styles.infoValue}>{age}</dd>
              </>
              }

              {breed &&
              <>
                <dt className={classNames(styles.infoKey)}>Rasse:</dt>
                <dd className={styles.infoValue}>{breed}</dd>
              </>
              }
              {color &&
              <>
                <dt className={classNames(styles.infoKey)}>Farbe:</dt>
                <dd className={styles.infoValue}>{color}</dd>
              </>
              }

              {castrated &&
              <>
                <dt className={classNames(styles.infoKey)}>Kastriert:</dt>
                <dd className={styles.infoValue}>{formatMessage(messages[castrated])}</dd>
              </>
              }

              {vaccinated &&
              <>
                <dt className={classNames(styles.infoKey)}>Geimpft:</dt>
                <dd className={styles.infoValue}>{formatMessage(messages[vaccinated])}</dd>
              </>
              }

              {chipped &&
              <>
                <dt className={classNames(styles.infoKey)}>Gechipt:</dt>
                <dd className={styles.infoValue}>{formatMessage(messages[chipped])}</dd>
              </>
              }
              {chip &&
              <>
                <dt className={classNames(styles.infoKey)}>Gechipt:</dt>
                <dd className={styles.infoValue}>{chip}</dd>
              </>
              }

              {onTime &&
              <>
                <dt className={classNames(styles.infoKey)}>auf Zeit:</dt>
                <dd className={styles.infoValue}>{onTime}</dd>
              </>
              }
              {onTime &&
              <>
                <dt className={classNames(styles.infoKey)}>Entlaufen in:</dt>
                <dd className={styles.infoValue}>{onPlace}</dd>
              </>
              }

            </dl>
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: normalizedDescription }}/>
          </>
        }
      />
    </div>
  )
}

export default injectIntl(applyStyling(baseStyles)(AnimalItem))
