/** @flow */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Field } from 'redux-form'
import { ReCaptchaField } from 'components/basic/Forms/Fields'
import required from '../../../validators/required'

const ReCaptchaFormRow = (props: Object) => {
  const { site: { siteMetadata: { reCaptchaEnabled, reCaptchaSiteKey } } } = useStaticQuery(
    graphql`
      query ReCaptchaQuery {
        site {
          siteMetadata {
            reCaptchaEnabled
            reCaptchaSiteKey
          }
        }
      }
    `
  )

  return (reCaptchaEnabled ?
    <Field
      {...props}
      name="reCaptchaResponse"
      type="text"
      component={ReCaptchaField}
      validate={[ required ]}
      reCaptchaSiteKey={reCaptchaSiteKey}
    /> : ''
  )
}

export default ReCaptchaFormRow
