// @flow

import React from 'react'
import { compose, withProps } from 'recompose'
import { injectIntl } from 'gatsby-plugin-intl'
import { applyStyling } from '../../../lib/styling'
import AnimalPageLayout from './../AnimalPageLayout'
import { ANIMAL_TYPE_DOGS } from '../../../state/animalSearch'
import { withSearch } from '../../../enhancers/withSearch'
import withQueryWrapper from '../../../state/enchancers/animalSearch/withQueryWrapper'
import {
  mapAnimalData, toTeoType, toTeoGender, toTeoAgePhase, toTeoSize
} from '../../../provider/teo'
import { withPetFacets, withPetList } from '../../../provider/pet'

type Props = {
  styles: Object,
  animalSearch: Object,
  isLoading: boolean,
  filter: Object,
  facets: Object,
  pets: Object,
  intl: { locale: string }
}

const TeoAnimalsPage = ({
  styles, animalSearch, pets, filter, isLoading, facets, ...rest
}: Props) => {
  const items = (pets?.result || []).map(mapAnimalData)
  const totalCount = pets?.total || 0

  const { type } = animalSearch
  const breeds = (facets?.breed || []).filter(Boolean)
  return (
    <AnimalPageLayout
      isLoading={isLoading}
      loading={isLoading}
      animalType={ANIMAL_TYPE_DOGS}
      totalCount={totalCount}
      items={items}
      animalSearch={animalSearch}
      {...rest}
      filterItems={
        [
          {
            name: 'type',
            title: 'Was suchen Sie?',
            options:
              [
                { value: 'dog', label: 'Hunde', icon: ' icon-dogs' },
                { value: 'cat', label: 'Katzen', icon: ' icon-cat' },
                { value: 'bird', label: 'Vögel', icon: ' icon-bird' },
                { value: 'small', label: 'Kleintiere', icon: 'icon-small-animals' }
              ],
            value: 'small'
          },
          /* {
            name: 'isMissed',
            title: 'vermisste Tiere',
            icon: 'icon-missed',
            forMobile: true,
            isBtn: true,
            link: `/${locale}/lost-pets`
          },*/
          {
            name: 'gender',
            title: 'Geschlecht',
            options:
              [
                { value: '', label: 'alle', icon: 'icon-gender-both' },
                { value: 'female', label: 'weiblich', icon: ' icon-gender-female' },
                { value: 'male', label: 'männlich', icon: ' icon-gender-male' }
              ],
            value: ''// ,
            // disabled: filterNoGender
          },
          ...(type === 'dog' ? [ {
            name: 'size',
            title: 'Größe',
            options:
              [
                { value: 'small', label: 'klein', icon: 'icon-size-small' },
                { value: 'medium', label: 'mittel', icon: ' icon-size-medium' },
                { value: 'big', label: 'groß', icon: ' icon-size-big' }
              ],
            value: 'small'
            // disabled: filterNoSize
          } ] : []),
          {
            name: 'agePhase',
            title: 'Alter',
            options:
              [
                { value: 'baby', label: 'Jungtier', icon: 'icon-baby' },
                { value: 'adult', label: 'Erwachsen', icon: 'icon-graduation' },
                { value: 'senior', label: 'Senior', icon: ' icon-senior' }
              ],
            value: 'senior'
            // disabled: filterNoAge
          },
          {
            name: 'breed',
            title: 'Rasse',
            isSelect: true,
            options: [
              { value: '', label: 'alle' },
              ...breeds.map(value => ({ value, label: value }))
            ],
            value: 'ALL'
            // disabled: filterNoPlace
          }
          /* {
            name: 'skin',
            title: 'Spezielle Felle',
            info: 'Spezielle Felle information',
            options:
              [
                { value: 'special', label: 'Ja', icon: 'icon-success-2' },
                { value: 'not-special', label: 'Nein', icon: 'icon-close-2' }
              ],
            value: 'special',
            disabled: filterNoSkin
          },
          {
            name: 'shelter',
            title: 'Pflegestelle',
            info: 'Pflegestelle information',
            options:
              [
                { value: 'limited', label: 'begrenzt', icon: 'icon-clock-1' },
                { value: 'infinite', label: 'für immer', icon: 'icon-endless' }
              ],
            value: 'infinite',
            disabled: filterNoShelter
          }*/
        ]
      }
    />
  )
}

const petSearchMutator = (animalSearch: Object) => {
  const params = {}
  if (animalSearch.gender) {
    params.gender = toTeoGender(animalSearch.gender)
  }
  if (animalSearch.agePhase) {
    params.agePhase = toTeoAgePhase(animalSearch.agePhase)
  }
  if (animalSearch.type) {
    params.type = toTeoType(animalSearch.type)
  }
  if (animalSearch.size) {
    params.size = toTeoSize(animalSearch.size)
  }
  return {
    ...animalSearch,
    ...params
  }
}

export default compose(
  injectIntl,
  withSearch,
  withQueryWrapper,
  withPetList('animal_data', petSearchMutator),
  withProps(({ animalSearch: { type, size, gender, agePhase } }) => ({
    postType: 'animal_data',
    type: type !== undefined ? toTeoType(type) : undefined,
    size: size !== undefined ? toTeoSize(size) : undefined,
    gender: gender !== undefined ? toTeoGender(gender) : undefined,
    agePhase: agePhase !== undefined ? toTeoAgePhase(agePhase) : undefined
  })),
  withPetFacets,
  applyStyling()
)(TeoAnimalsPage)
