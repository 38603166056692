// @flow

import React from 'react'
import classNames from 'classnames'
import { Field } from 'redux-form'
import { applyStyling } from '../../lib/styling'
import baseStyles from './FilterGroup.scss'
import { RadioButtonsField } from 'components/basic/Forms/Fields'

type Props = {
  styles: Object,
  options: Object,
  name: String,
  disabled?: Boolean
}

const FilterGroup = ({
  styles, options, disabled, name
}: Props) => {
  function renderItem(option, index, name, isSelected, isDisabled, handleChange) {
    return (
      <div
        className={classNames(styles.item, isDisabled && styles['is-disabled'], isSelected && styles['is-checked'])}
        key={index}
      >
        <label className={styles.label}>
          <input
            className={styles.input}
            checked={isSelected}
            disabled={isDisabled}
            name={name}
            onChange={() => handleChange(option)}
            onClick={() => isSelected && handleChange({ value: null })}
            type="radio"
            value={index}
          />
          <span className={styles.labelText}>
            <span className={classNames(styles.icon, option.icon)} />
            {option.label}
          </span>
        </label>
      </div>
    )
  }

  return (
    <Field
      name={name}
      options={options}
      component={RadioButtonsField}
      renderItem={ renderItem }
      className={styles.root}
      disabled={disabled}
    />
  )
}

export default applyStyling(baseStyles)(FilterGroup)
