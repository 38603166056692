// @flow
import { Component } from 'react'
import { scrollToTarget } from 'helpers/scroll'

type Props = {
  newsSearch: Object,
  updateStateValue: (path: string, value: any) => void,
  children: (props: WithNewsSearchHandlersProps) => any
}

export type NewsSearchHandlers = {
  onQueryChange: Function,
  onPageChange: Function,
  onPerPageChange: Function,
  onSortByChange: Function,
}

export type WithNewsSearchHandlersProps = NewsSearchHandlers & {
  newsSearch: Object
}

class WithHandlersWrapper extends Component<Props> {
  onPageChange = (perPage: string) => {
    this.props.updateStateValue('page', perPage)
    scrollToTarget('filter-mask')
  }

  onPerPageChange = (perPage: string) => {
    this.props.updateStateValue('perPage', perPage)
  }

  onQueryChange = (query: string) => {
    this.props.updateStateValue('query', query)
  }

  onSortByChange = (query: string) => {
    this.props.updateStateValue('sortBy', query)
  }

  render() {
    const { newsSearch, children } = this.props
    return children({
      newsSearch,
      onPageChange: this.onPageChange,
      onPerPageChange: this.onPerPageChange,
      onSortByChange: this.onSortByChange,
      onQueryChange: this.onQueryChange
    })
  }
}

export default WithHandlersWrapper
