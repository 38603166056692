// @flow

import React from 'react'
import { applyStyling } from '../../lib/styling'
import { Row, Col } from '../../components/basic/Grid'
import { Form, Field } from 'redux-form'
import Button from '../../components/basic/Button'
import baseStyles from './ContactForm.scss'
import { InputField } from '../basic/Forms/Fields'
import required from '../../validators/required'
import { createEmailValidator } from '../../validators/email'
import ReCaptchaFormRow from 'components/basic/Forms/ReCaptchaFormRow'

type Props = {
  styles: Object,
  title?: string,
  handleSubmit: Function,
  isSubmitted: boolean,
  isLoading: boolean,
  hasError: boolean
}

const emailValidator = createEmailValidator('Required')

const ContactForm = ({
  styles, title, handleSubmit, isSubmitted, isLoading, hasError
}: Props) => (
  <div className={styles.root}>
    <div className={styles.header}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.requiredLegend}>Pflichtfelder *</div>
    </div>
    <div className={styles.formWrap}>
      {isSubmitted ?
        <div>Thank you</div> :
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="4" className={styles.formGroup}>
              <Field
                name="receiverEmail"
                component={InputField}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md="4" className={styles.formGroup}>
              <Field
                name="name"
                component={InputField}
                placeholder="Vor und Nachname"
              />
            </Col>
            <Col md="4" className={styles.formGroup}>
              <Field
                name="email"
                component={InputField}
                placeholder="E-Mail Adresse *"
                validate={[ required, emailValidator ]}
                required
              />
            </Col>
            <Col md="4" className={styles.formGroup}>
              <Field
                name="phone"
                component={InputField}
                placeholder="Telefonnummer"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="12" className={styles.formGroup}>
              <Field
                name="text"
                type="textarea"
                component={InputField}
                placeholder="Ihre Nachricht an uns ... *"
                className={styles.textarea}
                validate={[ required ]}
                required
              />
            </Col>
          </Row>

          <Row>
            <Col sm="12" className={styles.formGroup}>
              <ReCaptchaFormRow/>
            </Col>
          </Row>

          <div className={styles.btnWrap}>
            <Button
              styles={styles.btn}
              type="submit"
              iconRight="icon-right"
              disabled={isLoading}
            >
              Senden
            </Button>
            <div className={styles.extraInfo}>
              ... oder rufen Sie uns an unter:
              <a
                href="tel: 0211 - 65 18 50"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.phoneLink}
              >
                0211 - 65 18 50
              </a>
            </div>
          </div>
          { hasError ? 'Unable to send the form, please try later' : ''}
        </Form>}
    </div>
  </div>
)

export default applyStyling(baseStyles)(ContactForm)
