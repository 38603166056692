// @flow

import React, { useCallback, useRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useDropzone } from 'react-dropzone'
import { applyStyling } from '../../../../lib/styling'
import { Row, Col } from '../../../basic/Grid/index'
import Button from '../../../basic/Button/index'
import Modal from '../../../basic/Modal/index'
import baseStyles from './ImageUpload.scss'
import _ from 'lodash'

import useModalActions from '../../../../hooks/modalActions'
import { getUrl, revokeFileUrl } from 'components/basic/files/helpers'
import usePrevious from '../../../../hooks/previous'

export type ValueItemType = File | Object
export type ValueType = Array<ValueItemType>

type Props = {
  styles: Object,
  title?: string,
  intl: {
    formatMessage: Function
  },
  input: Object,
  images: Array<Object>,
  accept: string,
  value: ValueType,
  messages?: Object,
  onChange: (value: ValueType) => void,
  thumbnailItemField: string,
  previewItemField: string,
  enablePreview: boolean,
  ignoreDuplicateFilenames: boolean,
  maxFiles: number
}

const EMPTY_VALUE = []

const ImageUpload = (props: Props) => {
  const {
    // accept = 'image/*',
    thumbnailItemField = 'thumbnailUrl',
    previewItemField = 'url',
    enablePreview = true,
    ignoreDuplicateFilenames = false,
    input: { onChange },
    styles,
    images,
    maxFiles = 1
  } = props

  const [ files, setFiles ] = useState([])

  const prevFiles = usePrevious(files)
  useEffect(() => {
    // console.log(prevValue, value)
    _.difference(prevFiles, files).forEach(revokeFileUrl)
  }, [ files, prevFiles ])


  const { visible, closeModal, openModal } = useModalActions()

  useEffect(() => {
    if (visible) {
      // setFiles(images)
      setFiles(images.slice(0, maxFiles))
    }
  }, [ visible ])

  const onDrop = useCallback(acceptedFiles => {
    if (setFiles) {
      let newValue
      if (ignoreDuplicateFilenames) {
        const existingFileNames = files.map(v => v instanceof File ? v.name : null).filter(v => v)

        if (existingFileNames.length > 0) {
          newValue = [ ...files, ...acceptedFiles.filter(f => !existingFileNames.includes(f.name)) ]
        }
      }
      if (!newValue) {
        newValue = [ ...files, ...acceptedFiles ]
      }
      // onChange(newValue)
      // setFiles(newValue)
      setFiles(newValue.slice(0, maxFiles))
    }
  }, [ props.input.value, ignoreDuplicateFilenames, files ])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const fileInputElement = useRef(null)
  const triggerFileField = useCallback(() => {
    fileInputElement && fileInputElement.current && fileInputElement.current.click()
  }, [ fileInputElement ])

  const removeItem = useCallback((index: number) => {
    if (onChange && images) {
      const newValue = [ ...images ]
      newValue.splice(index, 1)
      onChange(newValue)
    }
  }, [ images, onChange ])

  const cancelItem = useCallback((index: number) => {
    if (files) {
      const newValue = [ ...files ]
      newValue.splice(index, 1)
      setFiles(newValue)
      // setFiles(newValue.slice(0, maxFiles))
    }
  }, [ files, setFiles ])

  const onSubmit = useCallback(() => {
    onChange(files)
    closeModal()
  })

  const items = Array.isArray(files) ? files : EMPTY_VALUE
  return (
    <div className={styles.root}>
      <Modal
        title="Bild hochladen"
        visible={visible}
        closeButton
        closeOnOverlayClick={true}
        onClose={closeModal}
        styles={styles.modal}
        footer={
          <>
            <Button outline color="gray-dark" styles={styles.modalBtn} onClick={closeModal}>
              Abbrechen
            </Button>
            <Button styles={styles.modalBtn} onClick={onSubmit}>OK</Button>
          </>
        }
      >
        <div className={styles.dragDropWrap} {...getRootProps()}>
          <span className={classNames(styles.dragDropIcon, 'icon-open-gallery')} />
          <h4 className={styles.dragDropTitle}>.PDF .JPG .PNG</h4>
          <input {...getInputProps()} ref={fileInputElement}/>
          {
            isDragActive ?
              <p>Ziehen Sie Ihr Bild in diesen Bereich ...</p> :
              <div>
                Ziehen Sie Ihr Bild in diesen Bereich oder suchen Sie <a href="#" onClick={triggerFileField}>hier</a>
              </div>
          }
        </div>

        <ul className={styles.imagesList}>
          {items.map((item, key) => (
            <li className={styles.imagesListItem} key={key}>
              <span className={classNames(styles.imageIcon, 'icon-open-gallery')} />
              <div className={styles.imageInfo}>
                {item.path}
                <div className={styles.imageLoader} />
              </div>
              <span
                className={classNames(styles.removeIcon, styles['is-small'], 'icon-close-2')}
                onClick={() => cancelItem(key)}
              />
            </li>
          ))}
        </ul>

      </Modal>

      <Button
        styles={styles.btn}
        iconRight="icon-open-gallery"
        onClick={openModal}
      >
        Bild auswählen
      </Button>

      {images.length > 0 ? <h4 className={styles.title}>Ausgewählte Bilder</h4> : ''}

      <Row>
        {images.map((item, key) => {
          const thumbnailUrl = getUrl(item, thumbnailItemField)
          const url = enablePreview ? getUrl(item, previewItemField) : null
          let thumbnail = <img className={styles.image} src={thumbnailUrl} alt={item.path}/>

          if (url) {
            thumbnail = <a href={url} target="_blank" rel="noopener noreferrer">{thumbnail}</a>
          }

          return (
            <Col md="4" className={styles.col} key={key}>
              <div className={styles.imageWrap}>
                {thumbnail}
                <span className={classNames(styles.removeIcon, 'icon-close-2')} onClick={() => removeItem(key)}/>
              </div>
            </Col>
          )
        })}

      </Row>
    </div>
  )
}

export default applyStyling(baseStyles)(ImageUpload)
