// @flow

import { formValueSelector, reduxForm } from 'redux-form'
import { compose, withProps, withStateHandlers } from 'recompose'
import { scrollToInvalid } from '../../basic/Forms/Fields'

import NewLostPetPage from './NewLostPetPage'
import { connect } from 'react-redux'
import { onPetSubmit } from '../common'

const form = 'lost'

const EMPTY_ARRAY = []
const selector = formValueSelector(form)

export default compose(
  withStateHandlers(
    { isSubmitted: false, isLoading: false, hasError: false },
    {
      setIsSubmitted: () => isSubmitted => ({ isSubmitted }),
      setIsLoading: () => isLoading => ({ isLoading }),
      setHasError: () => hasError => ({ hasError })
    }
  ),
  connect(state => ({
    images: selector(state, 'images') || EMPTY_ARRAY
  })),
  withProps(() => ({
    initialValues: {
      images: [],
      gender: 'male',
      size: 'small',
      agePhase: 'adult',
      confirmation: false
    }
  })),
  reduxForm({
    form,
    onSubmit: onPetSubmit('new-lost-pet'),
    onSubmitFail: scrollToInvalid
  })
)(NewLostPetPage)

