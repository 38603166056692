// @flow

import React, { useCallback } from 'react'
import classNames from 'classnames'
import { applyStyling } from '../../lib/styling'
// import { Link } from 'gatsby-plugin-intl'
import { Link, navigate } from '@reach/router'
import { injectIntl } from 'gatsby-plugin-intl'

import { reduxForm, Field, Form } from 'redux-form'
import { compose, withProps } from 'recompose'
import Button from '../../components/basic/Button'
import FilterGroup from '../FilterGroup'
import OffCanvas from '../../components/basic/OffCanvas'
import Device from '../../components/basic/Device'
import Tooltip from '../../components/basic/Tooltip'
import baseStyles from './FilterSidebar.scss'
import { SelectField } from '../../components/basic/Forms/Fields'
import useModalActions from '../../hooks/modalActions'
import { withPhoneDetector } from '../../enhancers/withDeviceDetector'
/* $FlowFixMe: ignore */
import { encode } from 'querystring'
import { defaultValues } from '../../state/animalSearch'
import { withLocation } from 'lib/locationContext'

type Props = {
  isPhone: boolean,
  reset: Function,
  handleSubmit: Function,
  onResetFilters: Function,
  onPartialResetFilters: Function,
  styles: Object,
  items: Object
}

const Index = ({
  styles, items, handleSubmit, onResetFilters, onPartialResetFilters, reset
}: Props) => {
  const { visible, closeModal, openModal } = useModalActions()

  const clearForm = useCallback(() => {
    // onResetFilters()
    // reset()
    onPartialResetFilters([ 'query', 'perPage', 'sortBy' ])
  }, [ onResetFilters, reset, onPartialResetFilters ])

  const submitForm = useCallback(() => {
    handleSubmit()
    closeModal()
  }, [ handleSubmit ])

  const ChildNodesComponent = () => (
    <>
      {items.map((item, index) => (
        item.isBtn ?
          <div key={index} className={classNames(styles.btnWrap, item.forMobile && styles['for-mobile'])}>
            <Link to={item.link} replace className={styles.btnLink}>
              <Button iconRight={item.icon} styles={styles.btn} block>
                {item.title}
              </Button>
            </Link>
          </div>
          :
          !item.disabled ?
            <div
              className={classNames(styles.groupContainer, item.forMobile && styles['for-mobile'])}
              key={index}
            >
              <div className={styles.groupHeader}>
                {item.info ?
                  <div className={styles.infoWrapper}>
                    <span>{item.title}</span>
                    <Device tablet desktop>
                      <Tooltip
                        className={styles.tooltip}
                        content={
                          <span className={styles.tooltipContent}>
                            {item.info}
                          </span>
                        }
                        placement={'right'}
                        delay={200}
                      >
                        <Button
                          color="link"
                          size="xs"
                          styles={styles.infoIcon}
                          iconRight="icon-info"
                        />
                      </Tooltip>
                    </Device>
                  </div>
                  :
                  item.title}
              </div>
              {item.isSelect ?
                <div className={styles.selectWrap}>
                  <Field
                    name={item.name}
                    component={SelectField}
                    options={item.options}
                    isSearchable={false}
                    simpleValue
                    componentProps={{
                      styles: styles.select
                    }}
                    hideSeparator
                    onBlur={e => e.preventDefault()}
                    isDisabled={item.disabled}
                  />
                </div>
                :
                <FilterGroup
                  name={item.name}
                  options={item.options}
                  value={item.value}
                  styles={styles.group}
                  disabled={item.disabled}
                />
              }
            </div> :
            null
      ))}
      <div className={styles.btnWrap}>
        <Button styles={styles.btn} block type="button" onClick={clearForm}>
          Filter zurücksetzen
        </Button>
      </div>
    </>
  )

  const triggerRenderer = (onClick: Function, isOpen: boolean) => (
    <Button
      outline onClick={openModal}
      icon="icon-map-marker-3"
      styles={styles.filterTrigger}
    >
      Filtern
    </Button>
  )

  return (
    <Form onSubmit={handleSubmit}>
      <div className={styles.root}>
        <Device tablet desktop>
          <h2 className={styles.title}>
            Filter anpassen
          </h2>
          <ChildNodesComponent />
        </Device>
        <Device phone>
          <OffCanvas
            isOpen={visible}
            styles={styles.offcanvas}
            customBurgerIcon="icon-menu"
            customCrossIcon="icon-close-2"
            side="right"
            title="Filter anpassen"
            triggerRenderer={triggerRenderer}
            onOverlayClose={closeModal}
            onClose={closeModal}
            footer={
              <>
                <Button
                  type="button"
                  outline
                  color="gray-dark"
                  styles={styles.footerBtn}
                  onClick={closeModal}
                >
                  Zurück
                </Button>
                <Button styles={styles.footerBtn} type="button" onClick={submitForm}>OK</Button>
              </>
            }
          >
            <ChildNodesComponent />
          </OffCanvas>
        </Device>
      </div>
    </Form>
  )
}

export default compose(
  injectIntl,
  withPhoneDetector,
  withLocation,
  withProps(ownProps => ({
    initialValues: {
      ...(ownProps.animalSearch || ownProps.newsSearch)
    },
    onChange: (values, dispatch, props, previousValues) => {
      if (!ownProps.isPhone) {
        const propName = Object.keys(values).reduce((acc, key) => {
          return (values[key] !== previousValues[key]) ? key : acc
        }, '')
        if (propName) {
          ownProps.updateStateValue(propName, values[propName])
        }
      }
    },
    onSubmit: values => {
      ownProps.setSearchValue(values)
      const { location } = ownProps
      setTimeout(() => {
        const params = Object.keys(values).reduce((acc, key) => {
          if (values[key] && values[key] !== defaultValues[key]) {
            acc[`as.${key}`] = values[key]
            return acc
          }
          return acc
        }, {})
        navigate(
          `${location.pathname}?${encode(params)}`,
          { replace: true }
        )
      }, 100)
    }
  })),
  reduxForm({
    form: 'search',
    enableReinitialize: true,
    destroyOnUnmount: false
  }),
  applyStyling(baseStyles)
)(Index)
