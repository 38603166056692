// @flow

/* eslint-disable max-len */
import React from 'react'
import classNames from 'classnames'
import { applyStyling } from '../../lib/styling'
import BackToTop from '../../components/BackToTop/index'
import { Container } from '../../components/basic/Grid'
import HeroTeaser from '../../components/HeroTeaser/index'
import Teasers from '../../modules/Teasers'
import _get from 'lodash/get'
import BackBtn from '../../components/BackBtn'
import PageGalleryWrapper from '../../state/enchancers/pageGalleryWrapper'

import baseStyles from './simple.scss'

type Props = {
  styles: Object,
  imageLandscape: string,
  imageLandscape2x: string,
  imageLandscape3x: string,
  imageLandscape4x: string,
  heroTeaserTitle: string,
  heroTeaserSubtitle: string,
  content: string,
  isLoading: boolean,
  page: Object
}

class Simple extends React.Component<Props> {
  instance: any
  getCustomJavascript() {
    return _get(this.props.page, 'acf.custom_javascript') || null
  }
  getCustomHtml() {
    return _get(this.props.page, 'acf.custom_html') || null
  }

  componentDidMount() {
    const customJavaScript = this.getCustomJavascript()

    if (customJavaScript) {
      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.innerHTML = customJavaScript
      this.instance.appendChild(s)
    }
  }

  render() {
    const {
      styles,
      imageLandscape,
      imageLandscape2x,
      imageLandscape3x,
      imageLandscape4x,
      heroTeaserTitle,
      heroTeaserSubtitle,
      content,
      isLoading,
      page
    } = this.props
    const customJavaScript = this.getCustomJavascript()
    const customHtml = this.getCustomHtml()
    return (
      <div className={classNames(styles.root, { 'is-loading': isLoading })}>
        <HeroTeaser
          imageLandscape={imageLandscape}
          imageLandscape2x={imageLandscape2x}
          imageLandscape3x={imageLandscape3x}
          imageLandscape4x={imageLandscape4x}
          title={heroTeaserTitle}
          subtitle={heroTeaserSubtitle}
          styles={styles.teaser}
        />

        <div className={styles.container}>
          <Container>
            <BackBtn/>
            <PageGalleryWrapper><div dangerouslySetInnerHTML={{ __html: content }}/></PageGalleryWrapper>
            {customJavaScript && <div ref={el => (this.instance = el)} />}
            {customHtml && <div dangerouslySetInnerHTML={{ __html: customHtml }}/>}
            {page && page.acf && page.acf.teasers &&
            <Teasers teasers={page.acf.teasers}/>}
          </Container>
        </div>
        <BackToTop />
      </div>
    )
  }
}

export default applyStyling(baseStyles)(Simple)
