/** @flow */
import React, { Component } from 'react'
import TsbobMapMarker from '@fcse/tsbob/dist/components/MapMarker'
import { applyStyling } from '../../../../lib/styling'
import baseStyles from './MapMarker.scss'
import classNames from 'classnames'

type Props = {
  isHovered: boolean,
  isSelected: boolean,
  zIndex: number,
  place: Object,
  styles: Object
}

class MapMarker extends Component<Props> {
  props: Object

  contentRenderer = () => {
    const { styles } = this.props
    return (
      <span className={classNames(styles.markerIcon, 'icon-dogs')}/>
    )
  }

  tooltipRenderer = (p: Object) => {
    return (
      <div dangerouslySetInnerHTML={{ __html: p.tooltipText }} />
    )
  }

  detailRenderer = (p: Object) => {
    return (
      <div dangerouslySetInnerHTML={{ __html: p.tooltipText }} />
    )
  }

  render() {
    const { isHovered, isSelected, zIndex, place, styles, onPlaceSelect } = this.props
    return (
      <TsbobMapMarker
        {...{
          className: styles.mapMarker,
          isHovered,
          isSelected,
          tooltipRenderer: this.tooltipRenderer,
          contentRenderer: this.contentRenderer,
          detailRenderer: this.detailRenderer,
          place,
          zIndex,
          isContentInsideMarker: true,
          onPlaceSelect
        }}
        styles={{
          root: classNames(isSelected && 'marker--selected'),
          detail: classNames(isSelected && styles.detail),
          tooltip: classNames(isHovered && styles.tooltip)
        }}
      />
    )
  }
}

export default applyStyling(baseStyles)(MapMarker)
