// @flow

/* eslint-disable max-len */
import React from 'react'

/* $FlowFixMe: ignore */
import { encode } from 'querystring'
import { useDataLoader } from './hooks'
import { createGeneralParams, createPetSearchParams } from './helpers/params'

export const withPetList = (type: string, petSearchMutator?: Function) => (WrappedComponent: any) => {
  const PetListAwareComponent = (props: Object) => {
    const { animalSearch } = props
    const params = {
      ...createGeneralParams(animalSearch),
      ...createPetSearchParams(petSearchMutator ? petSearchMutator(animalSearch) : animalSearch)
    }
    const url = `/wp-json/wp/v2/${type}?${encode(params)}&_embed=1`

    const { data, isLoading } = useDataLoader(url)
    return (
      <WrappedComponent {...props} pets={data} isLoading={isLoading}/>
    )
  }

  return PetListAwareComponent
}

export const withPetFacets = (WrappedComponent: any) => {
  const NewsFacetsAwareComponent = (props: Object) => {
    /* eslint-disable-next-line */
    const params = {
      type: props.type,
      post_type: props.postType,
      age_phase: props.agePhase,
      gender: props.gender,
      size: props.size
    }
    const url = `/wp-json/wp/v2/pet_facets?${encode(params)}`
    const { data, isLoading } = useDataLoader(url)
    return (
      <WrappedComponent {...props} facets={data && data.result} isLoading={props.isLoading || isLoading} />
    )
  }
  return NewsFacetsAwareComponent
}
