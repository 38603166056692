// @flow

/* eslint-disable max-len */
import React from 'react'
import { applyStyling } from '../../lib/styling'
import BackToTop from '../BackToTop/index'
import { Container, Row, Col } from '../basic/Grid'
import TeamTeaser from './TeamTeaser'
import HeroTeaser from '../HeroTeaser/index'

import baseStyles from './TeamPage.scss'
import { withTeamMembers } from '../../provider/team'

type Props = {
  styles: Object,
  departments: Array<Object>,
  imageLandscape: string,
  imageLandscape2x: string,
  imageLandscape3x: string,
  imageLandscape4x: string,
  content: string,
  heroTeaserTitle: string,
  heroTeaserSubtitle: string
}

class TeamPage extends React.Component<Props> {
  render() {
    const {
      styles,
      departments,
      imageLandscape,
      imageLandscape2x,
      imageLandscape3x,
      imageLandscape4x,
      content,
      heroTeaserTitle,
      heroTeaserSubtitle
    } = this.props

    return (
      <div className={styles.root}>
        <HeroTeaser
          imageLandscape={imageLandscape}
          imageLandscape2x={imageLandscape2x}
          imageLandscape3x={imageLandscape3x}
          imageLandscape4x={imageLandscape4x}
          title={heroTeaserTitle}
          subtitle={heroTeaserSubtitle}
          styles={styles.teaser}
        />

        <div className={styles.container}>
          <Container>
            <div dangerouslySetInnerHTML={{ __html: content }}/>

            {departments.map((department, index) => (
              <>
                <h3 className={styles.title}>{department.name}</h3>
                <Row>
                  {department.employees.map((employee, index) => (
                    <Col md="3" className={styles.col} key={index}>
                      <TeamTeaser
                        title={employee.name}
                        text={employee.info}
                        image={employee.image}
                        link={employee.link}
                      />
                    </Col>
                  ))}
                </Row>
              </>
            ))}
          </Container>
        </div>
        <BackToTop />
      </div>
    )
  }
}

export default applyStyling(baseStyles)(withTeamMembers(TeamPage))
