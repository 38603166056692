// @flow
import React from 'react'
import loadable from '@loadable/component'
import { useStaticQuery, graphql } from 'gatsby'

const TsbobMapTilerMap = loadable(() => import('@fcse/tsbob/dist/components/MapTilerMap'))

const MapTilerMap = (props: Object) => {
  const { site: { siteMetadata: { mapTailerKey } } } = useStaticQuery(
    graphql`
      query MapTailerKeyQuery {
        site {
          siteMetadata {
            mapTailerKey
          }
        }
      }
    `
  )
  return (
    <TsbobMapTilerMap mapTilerApiKey={mapTailerKey} mapTilerTheme="basic" {...props} />
  )
}

export default MapTilerMap
