// @flow

/* eslint-disable max-len */
import React from 'react'
import { applyStyling } from '../../lib/styling'
import BackToTop from '../BackToTop/index'
import Filtermask from '../Filtermask'
import Device from '../basic/Device'
import FilterSidebar from '../FilterSidebar'
import { Container } from '../basic/Grid'
import withQueryWrapper from '../../state/enchancers/animalSearch/withQueryWrapper'

import baseStyles from './PageFilterLayout.scss'
import Notification from '../Notification'

type Props = {
  styles: Object,
  items: Object,
  filterItems: Object,
  location: Object,
  filterNoSize?: Boolean,
  showAdBlock?: Boolean,
  children?: any
}

const PageFilterLayout = ({ styles, items, filterItems, filterNoSize, children, location }: Props) => {
  const FilterComponent = withQueryWrapper(props => (
    <FilterSidebar
      filterNoSize={filterNoSize}
      items={filterItems}
      styles={styles.filterSidebar}
      {...props}
    />
  ))

  const FiltermaskComponent = withQueryWrapper(({ animalSearch, ...rest }) => (
    <Filtermask
      styles={styles.filtermask}
      searchValues={animalSearch}
      {...rest}
    />
  ))

  return (
    <div className={styles.root}>
      <Notification/>
      <Device tablet desktop>
        <Container className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.sidebar}>
              <FilterComponent/>
            </div>
            <div className={styles.content}>
              <FiltermaskComponent />
              {children}
            </div>
          </div>
        </Container>
      </Device>

      <Device phone>
        <Container>
          <div className={styles.mobileToolbar}>
            <FilterComponent/>
            <FiltermaskComponent />
          </div>
        </Container>
        <div className={styles.mobileContent}>
          <Container>
            {children}
          </Container>
        </div>
      </Device>
      <BackToTop/>
    </div>
  )
}

export default applyStyling(baseStyles)(PageFilterLayout)
