export const onPetSubmit = apiPageType => async (values, dispatch, props) => {
  const { setIsSubmitted, setIsLoading, setHasError } = props
  try {
    setIsLoading(true)
    const formData = new FormData()
    Object.keys(values).forEach(key => {
      if (key === 'images') {
        for (let i = 0; i < values[key].length; i++) {
          formData.append(`images_${i}`, values[key][i])
        }
      } else {
        formData.append(key, values[key])
      }
    })
    const response = await fetch(`/wp-json/wp/v2/${apiPageType}`, {
      method: 'POST',
      body: formData
    })
    const content = await response.json()

    setIsLoading(false)
    if (String(content).match(/\d+/)) {
      setIsSubmitted(true)
    } else {
      setHasError(true)
    }
  } catch (e) {
    // console.log(e)
    setHasError(true)
    setIsLoading(false)
  }
}
