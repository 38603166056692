// @flow
import React, { PureComponent } from 'react'
import { Marker } from 'react-map-gl'
import { applyStyling } from '../../../../lib/styling'
import MapMarker from '../MapMarker'
import baseStyles from './MapTilerMarker.scss'

type Props = {
  lat: number,
  lng: number,
  place: Object,
  isSelected?: boolean,
  onPlaceSelect?: Function,
  styles: Object
}

type State = {
  isHovered: boolean,
  isSelected: boolean
}

class MapTilerMarker extends PureComponent<Props, State> {
  static getDerivedStateFromProps(props: Props, state: Object) {
    if (props.isSelected !== state.isSelected) {
      return {
        isHovered: state.isHovered,
        isSelected: props.isSelected
      }
    }
    return null
  }

  constructor(props: Props) {
    super(props)
    this.state = { isHovered: false, isSelected: false }
  }

  handleMouseEnter = () => {
    if (!this.state.isHovered && !this.state.isSelected) {
      this.setState({ isHovered: true })
    }
  }

  handleMouseLeave = () => {
    if (this.state.isHovered) {
      this.setState({ isHovered: false })
    }
  }

  handleSelect = () => {
    const { onPlaceSelect } = this.props
    if (onPlaceSelect) {
      onPlaceSelect()
    }
    this.setState({ isSelected: true, isHovered: false })
  }

  render() {
    const { lat, lng, place, styles, ...rest } = this.props
    const { isHovered, isSelected } = this.state
    return (
      <Marker latitude={lat} longitude={lng}>
        <div className={`${styles.root} ${isHovered ? styles['is-hovered'] : ''}`}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onClick={this.handleSelect}>
          <MapMarker lat={lat}
            lng={lng}
            place={place}
            isHovered={isHovered}
            isSelected={isSelected}
            styles={styles.hotelMarker}
            onPlaceSelect={this.handleSelect}
            {...rest} />
        </div>
      </Marker>
    )
  }
}

export default applyStyling(baseStyles)(MapTilerMarker)
