import { defineMessages } from 'gatsby-plugin-intl'

export default defineMessages({
  dog: {
    id: 'AnimalItem.dog',
    defaultMessage: 'Hund'
  },
  cat: {
    id: 'AnimalItem.cat',
    defaultMessage: 'Katze'
  },
  bird: {
    id: 'AnimalItem.bird',
    defaultMessage: 'Vögel'
  },
  small: {
    id: 'AnimalItem.small',
    defaultMessage: 'Kleintier/Nager'
  },
  reptile: {
    id: 'AnimalItem.reptile',
    defaultMessage: 'Reptil'
  },
  large: {
    id: 'AnimalItem.large',
    defaultMessage: 'Großtier'
  },
  male: {
    id: 'AnimalItem.male',
    defaultMessage: 'männlich'
  },
  female: {
    id: 'AnimalItem.female',
    defaultMessage: 'weiblich'
  },
  smallSize: {
    id: 'AnimalItem.smallSize',
    defaultMessage: 'Klein'
  },
  mediumSize: {
    id: 'AnimalItem.mediumSize',
    defaultMessage: 'Mittel'
  },
  bigSize: {
    id: 'AnimalItem.bigSize',
    defaultMessage: 'Groß'
  },
  yes: {
    id: 'AnimalItem.yes',
    defaultMessage: 'Ja'
  },
  no: {
    id: 'AnimalItem.no',
    defaultMessage: 'Nein'
  },
  unknown: {
    id: 'AnimalItem.unknown',
    defaultMessage: 'Nicht bekannt'
  }
})
