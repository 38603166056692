// @flow

import { reduxForm/* , SubmissionError*/ } from 'redux-form'
import { compose, withProps, withStateHandlers } from 'recompose'
import ContactForm from './ContactForm'
import { scrollToInvalid } from '../basic/Forms/Fields'

const form = 'contact'

export default compose(
  withStateHandlers(
    { isSubmitted: false, isLoading: false, hasError: false },
    {
      setIsSubmitted: () => isSubmitted => ({ isSubmitted }),
      setIsLoading: () => isLoading => ({ isLoading }),
      setHasError: () => hasError => ({ hasError })
    }
  ),
  withProps(() => ({
    initialValues: {
      receiverEmail: 'An: info@tierheim-duesseldorf.de'
    }
  })),
  reduxForm({
    form,
    onSubmit: async (values, dispatch, props) => {
      const { setIsSubmitted, setIsLoading, setHasError } = props
      try {
        setIsLoading(true)
        const response = await fetch('/wp-json/wp/v2/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ ...values, animalType: props.animalType })
        })

        setIsLoading(false)
        if (response) {
          setIsSubmitted(true)
        }
      } catch (e) {
        // console.log(e)
        setHasError(true)
        setIsLoading(false)
      }
    },
    onSubmitFail: scrollToInvalid
  })
)(ContactForm)
