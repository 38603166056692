import { defineMessages } from 'gatsby-plugin-intl'

export default defineMessages({
  baby: {
    id: 'Details.baby',
    defaultMessage: 'Jungtier'
  },
  adult: {
    id: 'Details.adult',
    defaultMessage: 'Erwachsen'
  },
  senior: {
    id: 'Details.senior',
    defaultMessage: 'Senior'
  },
  dog: {
    id: 'Details.dog',
    defaultMessage: 'Hund'
  },
  cat: {
    id: 'Details.cat',
    defaultMessage: 'Katze'
  },
  bird: {
    id: 'Details.bird',
    defaultMessage: 'Vögel'
  },
  small: {
    id: 'Details.small',
    defaultMessage: 'Kleintier/Nager'
  },
  reptile: {
    id: 'Details.reptile',
    defaultMessage: 'Reptil'
  },
  large: {
    id: 'Details.large',
    defaultMessage: 'Großtier'
  },
  male: {
    id: 'Details.male',
    defaultMessage: 'männlich'
  },
  female: {
    id: 'Details.female',
    defaultMessage: 'weiblich'
  },
  unknown: {
    id: 'Details.unknown',
    defaultMessage: 'not determinable'
  },
  smallSize: {
    id: 'Details.smallSize',
    defaultMessage: 'Klein'
  },
  mediumSize: {
    id: 'Details.mediumSize',
    defaultMessage: 'Mittel'
  },
  bigSize: {
    id: 'Details.bigSize',
    defaultMessage: 'Groß'
  }
})
