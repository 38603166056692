// @flow

/* eslint-disable max-len */
import React from 'react'
import { applyStyling } from '../../lib/styling'
import BackToTop from '../BackToTop/index'
import { Container } from '../basic/Grid'
import BackBtn from '../BackBtn'
import Share from '../Share'
import Profile from '../Profile'
import HeroTeaser from '../HeroTeaser/index'
import moment from 'moment'
import Gallery from '../basic/Gallery/index'

import baseStyles from './NewsDetailsPage.scss'

type Props = {
  styles: Object,
  imageGallery: Array<Object>,
  imageGalleryPosition: string,
  imageLandscape: string,
  imageLandscape2x: string,
  imageLandscape3x: string,
  imageLandscape4x: string,
  content: string,
  heroTeaserTitle: string,
  heroTeaserSubtitle: string,
  authorPhoto: string,
  authorName: string,
  authorPosition: string,
  briefTitle: string,
  briefDescription: string,
  showShareLink: boolean,
  date: string,
}

class NewsDetailsPage extends React.PureComponent<Props> {
  render() {
    const {
      styles,
      imageLandscape,
      imageLandscape2x,
      imageLandscape3x,
      imageLandscape4x,
      content,
      heroTeaserTitle,
      heroTeaserSubtitle,
      authorPhoto,
      authorName,
      authorPosition,
      briefTitle,
      briefDescription,
      showShareLink,
      imageGallery,
      imageGalleryPosition,
      date
    } = this.props

    const gallery = imageGallery && imageGallery.length ?
      <Gallery
        items={imageGallery.map(v => ({
          original: v.url,
          thumbnail: v.sizes.medium
        }))}
        showNav
        showThumbnails={true}
        showAtOnce={1}
        startIndex={0}
        styles={styles.gallery}
        lazyLoad
      />
      : ''

    const postDate = date ? moment(date).format('DD.MM.YYYY') : ''
    return (
      <div className={styles.root}>
        <HeroTeaser
          imageLandscape={imageLandscape}
          imageLandscape2x={imageLandscape2x}
          imageLandscape3x={imageLandscape3x}
          imageLandscape4x={imageLandscape4x}
          title={heroTeaserTitle}
          subtitle={heroTeaserSubtitle}
          styles={styles.teaser}
        />

        <div className={styles.container}>
          <Container>
            <div className={styles.toolbar}>
              <BackBtn />
              {showShareLink && <Share />}
            </div>

            {authorName && <Profile
              title={briefTitle}
              date={postDate}
              info={
                <>
                  <div dangerouslySetInnerHTML={{ __html: briefDescription }}/>
                </>
              }
              image={authorPhoto}
              name={authorName}
              position={authorPosition}
            /> || postDate}
            <div className={styles.description}>
              {imageGalleryPosition === 'top' && gallery}
              <div className={styles.descriptionWrap} dangerouslySetInnerHTML={{ __html: content }}/>
              {imageGalleryPosition === 'bottom' && gallery}
            </div>
          </Container>
        </div>
        <BackToTop />
      </div>
    )
  }
}

export default applyStyling(baseStyles)(NewsDetailsPage)
