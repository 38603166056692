// @flow

/* eslint-disable max-len */
import React from 'react'
import { compose, withProps } from 'recompose'
import { injectIntl } from 'gatsby-plugin-intl'
import { applyStyling } from '../../../lib/styling'
import AnimalPageLayout from './../AnimalPageLayout'
import { withSearch } from '../../../enhancers/withSearch'
import withQueryWrapper from '../../../state/enchancers/animalSearch/withQueryWrapper'
import { withPetList, withPetFacets } from '../../../provider/pet'
import _get from 'lodash/get'
import BaseItem from '../../../components/AnimalsList/BaseItem'

type Props = {
  styles: Object,
  animalSearch: Object,
  pets: Object,
  isLoading: boolean,
  intl: { locale: string },
  facets: Object
}

const LostPetsPage = ({ styles, animalSearch, pets, isLoading, intl: { locale }, facets, ...rest }: Props) => {
  const { page, type } = animalSearch

  const items = (pets && pets.result || []).map(item => ({
    id: item.id,
    photos: _get(item, 'acf.images', []),
    link: `/${item.slug}`,
    // title: _get(item, 'title.rendered'),
    title: _get(item, 'acf.pet_name'),
    type: _get(item, 'acf.type'),
    gender: _get(item, 'acf.gender'),
    color: _get(item, 'acf.color'),
    breed: _get(item, 'acf.breed'),
    size: _get(item, 'acf.size'),
    age: undefined,
    agePhase: _get(item, 'acf.agePhase'),
    onTime: _get(item, 'acf.escape_date'),
    onPlace: _get(item, 'acf.escape_place'),
    chipped: undefined,
    chip: _get(item, 'acf.chip'),
    castrated: undefined,
    vaccinated: undefined,
    description: _get(item, 'content.rendered')
  }))

  const filterNoColor = false
  const filterNoGender = false
  const filterNoSize = [ 'bird', 'cat', 'small' ].indexOf(type) !== -1
  const filterNoAge = [ 'bird' ].indexOf(type) !== -1
  const filterNoBreed = [ 'small' ].indexOf(type) !== -1
  // const filterNoSkin = [ 'bird', 'small' ].indexOf(type) !== -1
  // const filterNoShelter = [ 'bird', 'small' ].indexOf(type) !== -1

  return (
    <AnimalPageLayout
      isLoading={isLoading}
      filterItems={[
        {
          name: 'type',
          title: 'Was suchen Sie?',
          options:
            [
              { value: 'dog', label: 'Hunde', icon: ' icon-dogs' },
              { value: 'cat', label: 'Katzen', icon: ' icon-cat' },
              { value: 'bird', label: 'Vögel', icon: ' icon-bird' },
              { value: 'small', label: 'Kleintiere', icon: 'icon-small-animals' }
            ],
          value: 'small'
        },
        /* {
          name: 'isMissed',
          title: 'vermisste Tiere',
          icon: 'icon-missed',
          forMobile: true,
          isBtn: true,
          link: `/${locale}/lost-pets`
        },*/
        {
          name: 'gender',
          title: 'Geschlecht',
          options:
            [
              { value: '', label: 'alle', icon: 'icon-gender-both' },
              { value: 'female', label: 'weiblich', icon: ' icon-gender-female' },
              { value: 'male', label: 'männlich', icon: ' icon-gender-male' }
            ],
          value: '',
          disabled: filterNoGender
        },
        {
          name: 'size',
          title: 'Größe',
          options:
            [
              { value: 'small', label: 'klein', icon: 'icon-size-small' },
              { value: 'medium', label: 'mittel', icon: ' icon-size-medium' },
              { value: 'big', label: 'groß', icon: ' icon-size-big' }
            ],
          value: 'small',
          disabled: filterNoSize
        },
        {
          name: 'agePhase',
          title: 'Alter',
          options:
            [
              { value: 'baby', label: 'Jungtier', icon: 'icon-baby' },
              { value: 'adult', label: 'Erwachsen', icon: 'icon-graduation' },
              { value: 'senior', label: 'Senior', icon: ' icon-senior' }
            ],
          value: 'senior',
          disabled: filterNoAge
        },
        ...(facets && facets.breed ? [ {
          name: 'breed',
          title: 'Rasse',
          isSelect: true,
          options:
            [
              { value: '', label: 'alle' },
              ...facets.breed.map(value => ({ value, label: value }))
            ],
          value: '',
          disabled: filterNoBreed
        } ] : []),
        ...(facets && facets.color ? [ {
          name: 'color',
          title: 'Farbe',
          isSelect: true,
          options:
            [
              { value: '', label: 'alle' },
              ...facets.color.map(value => ({ value, label: value }))
            ],
          value: '',
          disabled: filterNoColor
        } ] : [])/* ,
        {
          name: 'skin',
          title: 'Spezielle Felle',
          info: 'Spezielle Felle information',
          options:
            [
              { value: 'special', label: 'Ja', icon: 'icon-success-2' },
              { value: 'not-special', label: 'Nein', icon: 'icon-close-2' }
            ],
          value: 'special',
          disabled: filterNoSkin
        },
        {
          name: 'shelter',
          title: 'Pflegestelle',
          info: 'Pflegestelle information',
          options:
            [
              { value: 'limited', label: 'begrenzt', icon: 'icon-clock-1' },
              { value: 'infinite', label: 'für immer', icon: 'icon-endless' }
            ],
          value: 'infinite',
          disabled: filterNoShelter
        }*/
      ]}
      page={page}
      loading={isLoading}
      totalCount={pets && pets.total || 0}
      items={items}
      { ...rest }
      adBlock={<BaseItem
        title="Anzeige aufgeben"
        text="Wir helfen Ihnen ihr geliebtes Tier wiederzufinden"
        link="/tiervermittlung-vermisste-tiere-melden"
      />}
    />
  )
}

export default compose(
  injectIntl,
  withSearch,
  withQueryWrapper,
  withPetList('lost_pet'),
  withProps(({ animalSearch: { type } }) => ({
    type,
    postType: 'lost_pet'
  })),
  withPetFacets,
  applyStyling()
)(LostPetsPage)
