// @flow

/* eslint-disable max-len */
import React, { memo, useContext } from 'react'
import { applyStyling } from '../../lib/styling'
import { Link } from 'gatsby-plugin-intl'

import baseStyles from './BackBtn.scss'
import { withMenu } from '../../modules/Header/components/Mainnav'
import { PageContext } from '../../provider/page'

type Props = {
  styles: Object,
  menu: Array<Object>
}

const createPath = (page, menu) => {
  const acc = []
  function iterate(menu) {
    for (let item of menu) {
      if (item.slug === page.page.slug) {
        acc.push(item)
        return true
      }
      if (item?.subPages?.length) {
        const include = iterate(item?.subPages)
        if (include) {
          acc.push(item)
          return true
        }
      }
    }
  }
  iterate(menu)
  const breadcrumbPath = acc.reverse().map((item, idx) => ({
    label: item.name,
    url: (item.parentId !== 0 && item.slug !== page.page.slug) ? '/' + item.slug : ''
  }))

  if (page.page.type === 'lost_pet') {
    breadcrumbPath.push({
      label: 'Vermisste Tiere',
      url: '/tiervermittlung-vermisste-tiere'
    })
    breadcrumbPath.push({
      label: 'Vermisste Tiere (Liste)',
      url: '/vermisste-tiere-liste'
    })
    breadcrumbPath.push({
      label: page.title,
      url: ''
    })
  }

  if (page.page.type === 'private_pet') {
    breadcrumbPath.push({
      label: 'Private Tiervermittlung',
      url: '/tiervermittlung-private-tiervermittlung'
    })
    breadcrumbPath.push({
      label: 'Private Tiervermittlung',
      url: '/tiervermittlung-private-tiervermittlung-private-tiervermittlung-liste'
    })
    breadcrumbPath.push({
      label: page.title,
      url: ''
    })
  }

  if (page.page.type === 'animal_data') {
    breadcrumbPath.push({
      label: 'Tiervermittlung',
      url: ''
    })
    breadcrumbPath.push({
      label: 'Vermittlung',
      url: '/tiervermittlung-vermittlung'
    })
    breadcrumbPath.push({
      label: page.title,
      url: ''
    })
  }

  if (page.page.type === 'articles') {
    breadcrumbPath.push({
      label: 'News',
      url: '/news-news'
    })
    breadcrumbPath.push({
      label: page.title,
      url: ''
    })
  }

  return [
    ...(breadcrumbPath.length > 0 ? [ {
      label: 'Startseite',
      url: '/'
    } ] : []),
    ...breadcrumbPath
  ]
}

const BackBtn = memo((props: Props) => {
  const { styles, menu } = props
  const page = useContext(PageContext)
  // console.log('menu=', menu)
  // console.log('page=', page)
  const breadcrumbPath = createPath(page, menu)
  return (
    <ul className={styles.list}>
      {breadcrumbPath.map((item, idx) => {
        const isLast = breadcrumbPath.length === idx + 1
        return (
          <li key={idx}>
            {(isLast || !item.url) ? item.label : <Link to={item.url}>{item.label}</Link>}
            {!isLast && <span className="icon-right"/>}
          </li>
        )
      })}
    </ul>
  )
})

BackBtn.displayName = 'BackBtn'
export default applyStyling(baseStyles)(withMenu(BackBtn))
