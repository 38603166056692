// @flow

import React, { type AbstractComponent } from 'react'
import Device from 'components/basic/Device'

export const withPhoneDetector = <Props: {}>(
  Component: AbstractComponent<Props>,
  PhoneOverride?: AbstractComponent<Object>
): AbstractComponent<Props> => {
  return function WrapperComponent(props: Props) {
    const DeviceDetector = PhoneOverride || Device

    return (
      <DeviceDetector phone>
        { (matches: boolean) => (
          <Component {...props} isPhone={matches} />
        ) }
      </DeviceDetector>
    )
  }
}
