// @flow

/* eslint-disable max-len */
import React, { useContext, useEffect } from 'react'
import { applyStyling } from '../../../lib/styling'
import { compose } from 'recompose'
import PageFilterLayout from '../../PageFilterLayout'
import AnimalsList from '../../AnimalsList'
import HeroTeaser from '../../HeroTeaser/index'
import withQueryWrapper from '../../../state/enchancers/animalSearch/withQueryWrapper'
import { scrollToTarget } from '../../../helpers/scroll'
import LocationContext from '../../../lib/locationContext'
import classNames from 'classnames'

import baseStyles from './AnimalPageLayout.scss'

type Props = {
  styles: Object,
  items: Object,
  animalSearch: Object,
  filterItems: Object,
  totalCount: number,
  filterNoSize?: Boolean,
  filterNoAge?: Boolean,
  filterNoGender?: Boolean,
  filterNoPlace?: Boolean,
  filterNoSkin?: Boolean,
  filterNoShelter?: Boolean,
  adBlock?: any,
  isLoading: Boolean,
  imageLandscape: string,
  imageLandscape2x: string,
  imageLandscape3x: string,
  imageLandscape4x: string,
  heroTeaserTitle: string,
  heroTeaserSubtitle: string,
  animalTypesFilterProps?: Object
}

const AnimalPageLayout = ({
  filterItems,
  styles, items, animalSearch,
  totalCount, filterNoSize, filterNoAge, filterNoGender,
  filterNoPlace, filterNoSkin, filterNoShelter, adBlock,
  imageLandscape,
  imageLandscape2x,
  imageLandscape3x,
  imageLandscape4x,
  heroTeaserTitle,
  heroTeaserSubtitle,
  isLoading,
  animalTypesFilterProps = {}
}: Props) => {
  useEffect(() => {
    if (!isLoading) {
      scrollToTarget('animals_list')
    }
  }, [ animalSearch?.page ])

  const location = useContext(LocationContext)

  return (
    <div className={classNames(styles.root, isLoading ? styles.isLoading : '')}>
      <HeroTeaser
        imageLandscape={imageLandscape}
        imageLandscape2x={imageLandscape2x}
        imageLandscape3x={imageLandscape3x}
        imageLandscape4x={imageLandscape4x}
        title={heroTeaserTitle}
        subtitle={heroTeaserSubtitle}
      />

      <div id="animals_list"/>
      <PageFilterLayout
        location={location}
        filterItems={filterItems || [
          {
            name: 'type',
            title: 'Was suchen Sie?',
            options:
              [
                { value: 'dog', label: 'Hunde', icon: ' icon-dogs' },
                { value: 'cat', label: 'Katzen', icon: ' icon-cat' },
                { value: 'bird', label: 'Vögel', icon: ' icon-bird' },
                { value: 'small', label: 'Kleintiere', icon: 'icon-small-animals' }
              ],
            value: 'small'
          },
          {
            name: 'isMissed',
            title: 'vermisste Tiere',
            icon: 'icon-missed',
            forMobile: true,
            isBtn: true
          },
          {
            name: 'gender',
            title: 'Geschlecht',
            options:
              [
                { value: '', label: 'alle', icon: 'icon-gender-both' },
                { value: 'female', label: 'weiblich', icon: ' icon-gender-female' },
                { value: 'male', label: 'männlich', icon: ' icon-gender-male' }
              ],
            value: '',
            disabled: filterNoGender
          },
          {
            name: 'size',
            title: 'Größe',
            options:
              [
                { value: 'small', label: 'klein', icon: 'icon-size-small' },
                { value: 'medium', label: 'mittel', icon: ' icon-size-medium' },
                { value: 'big', label: 'groß', icon: ' icon-size-big' }
              ],
            value: 'small',
            disabled: filterNoSize
          },
          {
            name: 'agePhase',
            title: 'Alter',
            options:
              [
                { value: 'baby', label: 'Jungtier', icon: 'icon-baby' },
                { value: 'adult', label: 'Erwachsen', icon: 'icon-graduation' },
                { value: 'senior', label: 'Senior', icon: ' icon-senior' }
              ],
            value: 'senior',
            disabled: filterNoAge
          },
          {
            name: 'place',
            title: 'Rasse',
            isSelect: true,
            options:
              [
                { value: 'ALL', label: 'alle' },
                { value: 'HAM', label: 'Hamburg' },
                { value: 'FRA', label: 'Frankfurt' },
                { value: 'TXL', label: 'Berlin Tegel' },
                { value: 'DRS', label: 'Dresden' },
                { value: 'CGN', label: 'Köln-Bonn' }
              ],
            value: 'ALL',
            disabled: filterNoPlace
          },
          {
            name: 'skin',
            title: 'Spezielle Felle',
            info: 'Spezielle Felle information',
            options:
              [
                { value: 'special', label: 'Ja', icon: 'icon-success-2' },
                { value: 'not-special', label: 'Nein', icon: 'icon-close-2' }
              ],
            value: 'special',
            disabled: filterNoSkin
          },
          {
            name: 'shelter',
            title: 'Pflegestelle',
            info: 'Pflegestelle information',
            options:
              [
                { value: 'limited', label: 'begrenzt', icon: 'icon-clock-1' },
                { value: 'infinite', label: 'für immer', icon: 'icon-endless' }
              ],
            value: 'infinite',
            disabled: filterNoShelter
          }
        ]}
      >
        <AnimalsList
          totalCount={totalCount}
          items={items}
          adBlock={adBlock}
        />
      </PageFilterLayout>
    </div>
  )
}

export default compose(
  withQueryWrapper,
  applyStyling(baseStyles)
)(AnimalPageLayout)
