// @flow

/* eslint-disable max-len */
import React, { useMemo } from 'react'
import _get from 'lodash/get'
import { useDataLoader } from './hooks'

export const withTeamMembers = (WrappedComponent: any) => {
  const mapTeamMembers = (response: Object) => {
    const result = []

    for (let item of response) {
      const {
        title: { rendered: name },
        featured_image_src_full: image, acf: { position: info, department, link, showprofile }, type
      } = item
      const employee = {
        name,
        info,
        image,
        link: link || ((showprofile === 'yes' || type === 'volunteers') && `/${item.slug}`) || '/kontakt'
      }
      const departmentIdx = result.findIndex(d => d.name === department)
      if (departmentIdx !== -1) {
        result[departmentIdx].employees.push(employee)
      } else {
        result.push({
          name: department,
          employees: [ employee ]
        })
      }
    }
    return result
  }

  const TeamMembersAwareComponent = (props: Object) => {
    const template = _get(props, 'page.acf.template')
    /* eslint-disable-next-line */
    const url = `/wp-json/wp/v2/${template}?per_page=100`
    const { data, isLoading } = useDataLoader(url)
    const departments = useMemo(() => {
      return data ? mapTeamMembers(data.result) : []
    }, [ data, url ])

    return (
      <WrappedComponent {...props} departments={departments} isLoading={isLoading} template={template}/>
    )
  }

  return TeamMembersAwareComponent
}
