// @flow

import React from 'react'
import { Field } from 'redux-form'
import { applyStyling } from '../../../../lib/styling'
import FilterGroup from '../../../FilterGroup/index'
import { Row, Col } from '../../../basic/Grid/index'
import { Label } from '../../../basic/Forms/index'
import baseStyles from './MediationForm.scss'
import { EnhancedDatePickerField, InputField, SelectField } from '../../../basic/Forms/Fields'
import required from '../../../../validators/required'

type Props = {
  styles: Object
}

const MediationForm = ({
  styles
}: Props) => (
  <div className={styles.root}>
    <Row>
      <Col md="4" className={styles.formGroup}>
        <Field
          name="petName"
          component={InputField}
          placeholder="Name *"
          validate={[ required ]}
          required
        />
      </Col>
      <Col md="4" className={styles.formGroup}>
        <Field
          name="type"
          placeholder="Tierart"
          component={SelectField}
          options={[
            { value: 'dog', label: 'Hund' },
            { value: 'cat', label: 'Katze' },
            { value: 'small', label: 'Kleintier/Nager' },
            { value: 'bird', label: 'Vogel' },
            { value: 'reptile', label: 'Reptil' },
            { value: 'large', label: 'Großtier' }
          ]}
          isSearchable={false}
          simpleValue
          componentProps={{
            styles: styles.select
          }}
          hideSeparator
          onBlur={e => e.preventDefault()}
        />
      </Col>
    </Row>
    <Row>
      <Col md="4" className={styles.formGroup}>
        <Label className={styles.label}>Geschlecht</Label>
        <FilterGroup
          name="gender"
          options={[
            { value: 'female', label: 'weiblich', icon: ' icon-gender-female' },
            { value: 'male', label: 'männlich', icon: ' icon-gender-male' }
          ]}
        />
      </Col>
      <Col md="4" className={styles.formGroup}>
        <Label className={styles.label}>Größe</Label>
        <FilterGroup
          name="size"
          options={[
            { value: 'small', label: 'klein', icon: 'icon-size-small' },
            { value: 'medium', label: 'mittel', icon: ' icon-size-medium' },
            { value: 'big', label: 'groß', icon: ' icon-size-big' }
          ]}
        />
      </Col>
      <Col md="4" className={styles.formGroup}>
        <Label className={styles.label}>Lebensabschnitt</Label>
        <FilterGroup
          name="agePhase"
          options={[
            { value: 'baby', label: 'Jungtier', icon: 'icon-baby' },
            { value: 'adult', label: 'Erwachsen', icon: ' icon-graduation' },
            { value: 'senior', label: 'Senior', icon: ' icon-senior' }
          ]}
        />
      </Col>
    </Row>

    <Row>
      <Col md="4" className={styles.formGroup}>
        <Label className={styles.label}>Kastriert</Label>
        <Field
          name="castrated"
          component={SelectField}
          options={[
            { value: 'unknown', label: 'Nicht bekannt' },
            { value: 'yes', label: 'Ja' },
            { value: 'no', label: 'Nein' }
          ]}
          isSearchable={false}
          simpleValue
          componentProps={{
            styles: styles.select
          }}
          hideSeparator
          onBlur={e => e.preventDefault()}
        />
      </Col>
      <Col md="4" className={styles.formGroup}>
        <Label className={styles.label}>Geimpft</Label>
        <Field
          name="vaccinated"
          component={SelectField}
          options={[
            { value: 'unknown', label: 'Nicht bekannt' },
            { value: 'yes', label: 'Ja' },
            { value: 'no', label: 'Nein' }
          ]}
          isSearchable={false}
          simpleValue
          componentProps={{
            styles: styles.select
          }}
          hideSeparator
          onBlur={e => e.preventDefault()}
        />
      </Col>
      <Col md="4" className={styles.formGroup}>
        <Label className={styles.label}>Gechipt</Label>
        <Field
          name="chipped"
          component={SelectField}
          options={[
            { value: 'unknown', label: 'Nicht bekannt' },
            { value: 'yes', label: 'Ja' },
            { value: 'no', label: 'Nein' }
          ]}
          isSearchable={false}
          simpleValue
          componentProps={{
            styles: styles.select
          }}
          hideSeparator
          onBlur={e => e.preventDefault()}
        />
      </Col>
    </Row>

    <Row>
      <Col md="4" className={styles.formGroup}>
        <Field
          name="color"
          component={InputField}
          placeholder="Farbe"
        />
      </Col>
      <Col md="4" className={styles.formGroup}>
        <Field
          name="chip"
          component={InputField}
          placeholder="Chip-Nr."
        />
      </Col>
      <Col md="4" className={styles.formGroup}>
        <Field
          name="tattoo"
          component={InputField}
          placeholder="Tätowierung"
        />
      </Col>
    </Row>
    <Row>
      <Col md="4" className={styles.formGroup}>
        <Field
          name="escapeDate"
          component={EnhancedDatePickerField}
          labelCloseButton="Abbrechen"
          labelSubmitButton="OK"
          validate={[ required ]}
          inputProps={{
            placeholder: 'Entlaufen am *',
            required: true
          }}
          calendarProps={{
            showWeekNumbers: true
          }}
        />
      </Col>
      <Col md="4" className={styles.formGroup}>
        <Field
          name="escapePlace"
          component={InputField}
          placeholder="Entlaufen in *"
          validate={[ required ]}
          required
        />
      </Col>
      <Col md="4" className={styles.formGroup}>
        <Field
          name="district"
          component={InputField}
          placeholder="Stadtteil"
        />
      </Col>
    </Row>
    <div className={styles.extraForm}>
      <Row>
        <Col md="4" className={styles.formGroup}>
          <Field
            name="title"
            component={InputField}
            placeholder="Überschrift"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="12" className={styles.formGroup}>
          <Field
            name="description"
            type="textarea"
            component={InputField}
            placeholder="Kurze Beschreibung... *"
            className={styles.textarea}
            validate={[ required ]}
            required
          />
        </Col>
      </Row>
    </div>
  </div>
)

export default applyStyling(baseStyles)(MediationForm)
