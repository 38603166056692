// @flow

import React, { useMemo } from 'react'
import { applyStyling } from '../../lib/styling'
import { Container } from '../basic/Grid'
import classNames from 'classnames'

import baseStyles from './SitemapPage.scss'
import { withMenu } from '../../modules/Header/components/Mainnav'

type Props = {
  styles: Object,
  content: string,
  menu: Array<Object>
}

const SitemapPage = (props: Props) => {
  const {
    styles,
    // content,
    menu
  } = props
  const menuContent = useMemo(() => {
    const renderItems = (items, level = 0) => {
      return items.map((item, key) => {
        return (
          <div key={key} className={classNames(styles.item, level === 0 ? styles.itemRoot : '')}>
            {level === 0 && item.subPages
              ? item.name
              : <a href={`/de/${item.slug}`}>{item.name}</a>}
            {item.subPages && item.subPages.length ? renderItems(item.subPages, level + 1) : null }
          </div>
        )
      })
    }
    return renderItems(menu)
  }, [ menu ])

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Container>
          {menuContent}
        </Container>
      </div>
    </div>
  )
}

export default applyStyling(baseStyles)(withMenu(SitemapPage))
