// @flow
import React from 'react'
// import classNames from 'classnames'
import { applyStyling } from '../../../lib/styling'
import Teaser from '../../Teaser'
import baseStyles from './TeamTeaser.scss'

type Props = {
  styles: Object,
  title?: string,
  text?: string,
  btnLink?: string,
  image?: Object,
  isReversed?: Boolean
}

const TeamTeaser = ({
  styles, text, isReversed, btnLink, ...rest
}: Props) => (
  <Teaser
    styles={styles.root}
    textComponent={
      <>
        {text}
      </>
    }
    {...rest}
  />
)

export default applyStyling(baseStyles)(TeamTeaser)
