import { defineMessages } from 'gatsby-plugin-intl'

export default defineMessages({
  email: {
    id: 'PetOwnerContact.email',
    defaultMessage: 'Email'
  },
  mobile: {
    id: 'PetOwnerContact.mobile',
    defaultMessage: 'Mobile'
  },
  phone: {
    id: 'PetOwnerContact.phone',
    defaultMessage: 'Phone'
  },
  street: {
    id: 'PetOwnerContact.street',
    defaultMessage: 'Street'
  }
})
