// @flow

/* eslint-disable max-len */
import React, { useState, useCallback, useRef, useEffect } from 'react'
import classNames from 'classnames'
import useWindowScroll from '../../hooks/useWindowScroll'
import { FacebookShareButton, EmailShareButton, WhatsappShareButton } from 'react-share'
import Device from '../basic/Device'
import Tooltip from '../../components/basic/Tooltip'
import { applyStyling } from '../../lib/styling'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from '../basic/Dropdown/index'
import baseStyles from './Share.scss'
import LocationContext from '../../lib/locationContext'

type Props = {
  styles: Object,
  url?: string
}
const Share = (props: Props) => {
  const { styles, url } = props

  const [ dropdownOpen, setDropdownOpen ] = useState(false)
  const tooltipRef = useRef(0)
  const toggle = useCallback(() => setDropdownOpen(!dropdownOpen), [ dropdownOpen ])

  const scroll = useWindowScroll()

  const clickCopyLink = useCallback(() => {
    const clipboard = require('clipboard-polyfill')
    if (clipboard) {
      clipboard.writeText(url)
      tooltipRef && tooltipRef.current && tooltipRef.current._overlay && tooltipRef.current._overlay.show()
    }
  })

  useEffect(() => {
    tooltipRef && tooltipRef.current && tooltipRef.current.handleClose()
  }, [ scroll.x, scroll.y ])

  return (
    <Tooltip
      className={styles.tooltip}
      content={'Kopiert!'}
      placement="bottom"
      visible={true}
      rootClose
      trigger="none"
      refDeep={tooltipRef}
    >
      <Dropdown isOpen={dropdownOpen} toggle={toggle} styles={styles.root}>
        <DropdownToggle nav styles={styles.toggle}>
          Teilen
          <span className={classNames(styles.toggleIcon, 'icon-share')} />
        </DropdownToggle>
        <DropdownMenu styles={styles.menu}>
          <DropdownItem styles={styles.item}>
            <span className={classNames(styles.itemIcon, 'icon-facebook')} />
            <FacebookShareButton url={url}>Facebook</FacebookShareButton>
          </DropdownItem>
          <DropdownItem styles={styles.item}>
            <span className={classNames(styles.itemIcon, 'icon-mail')} />
            <EmailShareButton url={url}>E-Mail</EmailShareButton>
          </DropdownItem>
          <Device phone>
            <DropdownItem styles={styles.item}>
              <span className={classNames(styles.itemIcon, 'icon-whatsapp')} />
              <WhatsappShareButton url={url}>Whatsapp</WhatsappShareButton>
            </DropdownItem>
          </Device>
          <DropdownItem styles={styles.item}>
            <span className={classNames(styles.itemIcon, 'icon-link')}/>
            <span onClick={clickCopyLink}>Link kopieren</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Tooltip>
  )
}

const WrappedShare = ({ url, ...rest }: Props) => (
  <LocationContext.Consumer>
    { location => <Share {...rest} url={url || location.href}/>}
  </LocationContext.Consumer>
)

export default applyStyling(baseStyles)(WrappedShare)
