// @flow
export const File = global.window ? global.window.File : class {}

export const getUrl = (value: any, urlDataField?: string) => {
  if (value instanceof File) {
    return URL.createObjectURL(value)
  } else if (value && urlDataField && value[urlDataField]) {
    return value[urlDataField]
  }
  return ''
}

export const revokeFileUrl = (value: any) => {
  if (value instanceof File) {
    URL.revokeObjectURL(value)
  }
}

export const getMimeType = (value: any) => {
  if (value instanceof File) {
    return value.type
  } else if (value && value.mimeType) {
    return value.mimeType
  }
  return ''
}

export const getImageFallback = (value: any) => {
  // if (value instanceof File && getMimeType(value) === 'application/pdf') {
  //   return require('@images/theme/default/other/icon_pdf.png')
  // }
  return null
}

/* $FlowFixMe: ignore */
export const getBase64 = (file: File) => new Promise((resolve: Function, reject: Function) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(String(reader.result).split(',', 2)[1])
  reader.onerror = error => reject(error)
})
