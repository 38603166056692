// @flow

/* eslint-disable max-len */
import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { applyStyling } from '../../../lib/styling'
import AnimalGallery from '../../AnimalGallery/index'
import Device from '../../basic/Device/index'
import MobileCarousel from '../../MobileCarousel'

import baseStyles from './Gallery.scss'
import _ from 'lodash'

type Props = {
  styles: Object,
  photos: Array<Object>,
  title: String
}

const Gallery = (props: Props) => {
  const { styles, title, photos } = props

  const [ visible, setVisible ] = useState(false)
  const [ startIndex, setStartIndex ] = useState(0)
  const closeModal = useCallback(() => setVisible(false), [ setVisible ])
  const openModal = useCallback(startIndex => {
    setStartIndex(startIndex)
    photos && photos.length ? setVisible(true) : _.noop
  }, [ photos, setVisible ])

  const mainImage = photos && photos.length ? photos[0] : ''
  const images = photos && photos.map(v => ({ src: v })) || []

  return (
    <div className={styles.root}>
      <AnimalGallery
        title={title}
        photos={photos}
        visible={visible}
        closeModal={closeModal}
        startIndex={startIndex}
      />

      <Device tablet desktop>
        <div className={styles.gallery}>
          <div className={classNames(styles.imgWrap, styles['is-full'])}>
            <img src={mainImage} className={styles.img} onClick={() => openModal(0)} />
          </div>
          {images.length > 1 &&
          <div className={classNames(styles.imgWrap, styles['is-small'])}>
            {images.slice(1).map(({ src }, idx) => (
              <img key={idx} src={src} className={styles.img} onClick={() => openModal(idx + 1)} />
            ))}
          </div>}
        </div>
      </Device>
      <Device phone>
        <MobileCarousel
          styles={styles.carousel}
          images={images}
          onClick={openModal}
        />
      </Device>
    </div>
  )
}

export default applyStyling(baseStyles)(Gallery)
