// @flow

/* eslint-disable max-len */
import React from 'react'
import { compose } from 'recompose'
import { injectIntl } from 'gatsby-plugin-intl'
import { applyStyling } from '../../../lib/styling'
import AnimalPageLayout from './../AnimalPageLayout'
import { withSearch } from '../../../enhancers/withSearch'
import withQueryWrapper from '../../../state/enchancers/animalSearch/withQueryWrapper'
import { withPetList } from '../../../provider/pet'
import _get from 'lodash/get'
import BaseItem from './../../AnimalsList/BaseItem'

type Props = {
  styles: Object,
  animalSearch: Object,
  pets: Object,
  isLoading: boolean,
  intl: { locale: string }
}

const LostPetsPage = ({ styles, animalSearch, pets, isLoading, intl: { locale }, ...rest }: Props) => {
  const { page, type } = animalSearch

  const items = (pets && pets.result || []).map(item => ({
    id: item.id,
    photos: _get(item, 'acf.images', []),
    link: `/${item.slug}`,
    // title: _get(item, 'title.rendered'),
    title: _get(item, 'acf.pet_name'),
    type: _get(item, 'acf.type'),
    gender: _get(item, 'acf.gender'),
    size: _get(item, 'acf.size'),
    age: undefined,
    agePhase: _get(item, 'acf.agePhase'),
    breed: undefined,
    onTime: _get(item, 'acf.escape_date'),
    onPlace: _get(item, 'acf.escape_place'),
    chip: _get(item, 'acf.chip'),
    chipped: _get(item, 'acf.chipped'),
    castrated: _get(item, 'acf.castrated'),
    vaccinated: _get(item, 'acf.vaccinated'),
    description: _get(item, 'content.rendered')
  }))

  const filterNoGender = false
  const filterNoSize = [ 'bird', 'cat', 'small' ].indexOf(type) !== -1
  const filterNoAge = [ 'bird' ].indexOf(type) !== -1
  // const filterNoBreed = [ 'small' ].indexOf(type) !== -1
  // const filterNoSkin = [ 'bird', 'small' ].indexOf(type) !== -1
  // const filterNoShelter = [ 'bird', 'small' ].indexOf(type) !== -1

  return (
    <AnimalPageLayout
      animalTypesFilterProps={{
        showBirds: false
      }}
      isLoading={isLoading}
      filterItems={[
        {
          name: 'type',
          title: 'Was suchen Sie?',
          options:
            [
              { value: 'dog', label: 'Hunde', icon: ' icon-dogs' },
              { value: 'cat', label: 'Katzen', icon: ' icon-cat' },
              { value: 'bird', label: 'Vögel', icon: ' icon-bird' },
              { value: 'small', label: 'Kleintiere', icon: 'icon-small-animals' }
            ],
          value: 'small'
        },
        {
          name: 'gender',
          title: 'Geschlecht',
          options:
            [
              { value: '', label: 'alle', icon: 'icon-gender-both' },
              { value: 'female', label: 'weiblich', icon: ' icon-gender-female' },
              { value: 'male', label: 'männlich', icon: ' icon-gender-male' }
            ],
          value: '',
          disabled: filterNoGender
        },
        {
          name: 'size',
          title: 'Größe',
          options:
            [
              { value: 'small', label: 'klein', icon: 'icon-size-small' },
              { value: 'medium', label: 'mittel', icon: ' icon-size-medium' },
              { value: 'big', label: 'groß', icon: ' icon-size-big' }
            ],
          value: 'small',
          disabled: filterNoSize
        },
        {
          name: 'agePhase',
          title: 'Alter',
          options:
            [
              { value: 'baby', label: 'Jungtier', icon: 'icon-baby' },
              { value: 'adult', label: 'Erwachsen', icon: 'icon-graduation' },
              { value: 'senior', label: 'Senior', icon: ' icon-senior' }
            ],
          value: 'senior',
          disabled: filterNoAge
        }/* ,
        {
          name: 'breed',
          title: 'Rasse',
          isSelect: true,
          options:
            [
              { value: 'ALL', label: 'alle' },
              { value: 'HAM', label: 'Hamburg' },
              { value: 'FRA', label: 'Frankfurt' },
              { value: 'TXL', label: 'Berlin Tegel' },
              { value: 'DRS', label: 'Dresden' },
              { value: 'CGN', label: 'Köln-Bonn' }
            ],
          value: 'ALL',
          disabled: filterNoBreed
        },
        {
          name: 'skin',
          title: 'Spezielle Felle',
          info: 'Spezielle Felle information',
          options:
            [
              { value: 'special', label: 'Ja', icon: 'icon-success-2' },
              { value: 'not-special', label: 'Nein', icon: 'icon-close-2' }
            ],
          value: 'special',
          disabled: filterNoSkin
        },
        {
          name: 'shelter',
          title: 'Pflegestelle',
          info: 'Pflegestelle information',
          options:
            [
              { value: 'limited', label: 'begrenzt', icon: 'icon-clock-1' },
              { value: 'infinite', label: 'für immer', icon: 'icon-endless' }
            ],
          value: 'infinite',
          disabled: filterNoShelter
        }*/
      ]}
      page={page}
      loading={isLoading}
      totalCount={pets && pets.total || 0}
      items={items}
      { ...rest }
      adBlock={<BaseItem
        title="Anzeige aufgeben"
        text="Wir helfen Ihnen ihr geliebtes Tier wiederzufinden"
        link="/new-private-pet"
      />}
    />
  )
}

export default compose(
  injectIntl,
  withSearch,
  withQueryWrapper,
  withPetList('private_pet'),
  applyStyling()
)(LostPetsPage)
