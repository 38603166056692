// @flow

/* eslint-disable max-len */
import React from 'react'
import { applyStyling } from '../../lib/styling'
import Device from '../basic/Device'

import baseStyles from './Profile.scss'

type Props = {
  styles: Object,
  title?: String,
  date?: String,
  info?: Object,
  image?: Object,
  name?: String,
  position?: String
}

class Profile extends React.Component<Props> {
  render() {
    const { styles, title, date, info, image, name, position } = this.props

    return (
      <div className={styles.root}>
        <div className={styles.infoWrap}>
          <div className={styles.header}>
            <h2 className={styles.title}>{title}</h2>
            <Device tablet desktop>
              <span className={styles.date}>{date}</span>
            </Device>
          </div>
          <div className={styles.info}>
            {info}
          </div>
        </div>
        <div className={styles.profileWrap}>
          <img className={styles.profileImg} src={image} />
          <h5 className={styles.profileName}>{name}</h5>
          <span className={styles.profilePosition}>{position}</span>
        </div>
      </div>
    )
  }
}

export default applyStyling(baseStyles)(Profile)
