// @flow
/* eslint-disable max-len */
import React from 'react'
import { applyStyling } from '../../lib/styling'
import Device from '../../components/basic/Device'
import AnimalItem from './AnimalItem'
import BaseItem from './BaseItem'
import Pagination from '../../components/basic/Pagination'
import { compose } from 'recompose'
import withQueryWrapper from '../../state/enchancers/animalSearch/withQueryWrapper'

import baseStyles from './AnimalsList.scss'

type Props = {
  styles: Object,
  items: Object,
  onPageChange: Function,
  adBlock?: any,
  totalCount: number,
  animalSearch: Object
}

const AnimalsList = ({ styles, items, adBlock, onPageChange, totalCount, animalSearch }: Props) => {
  return (
    <div className={styles.root}>
      {adBlock}
      {items.length ?
      <>
        {items.map(item => (
          <AnimalItem
            photos={item.photos}
            link={item.link}
            image={item.image}
            title={item.title}
            color={item.color}
            type={item.type}
            gender={item.gender}
            size={item.size}
            age={item.age}
            agePhase={item.agePhase}
            breed={item.breed}
            chipped={item.chipped}
            chip={item.chip}
            castrated={item.castrated}
            vaccinated={item.vaccinated}
            onTime={item.onTime}
            onPlace={item.onPlace}
            description={item.description}
            id={item.id}
            key={item.id}
          />
        ))}
        <Device tablet>
          {matches => (
            <Pagination
              previousLabel="Zurück"
              nextLabel="Weiter"
              initialSelected={animalSearch.page || 0}
              results={totalCount}
              resultsPerPage={animalSearch.perPage}
              pageRangeDisplayed={matches ? 1 : 3}
              marginPagesDisplayed={1}
              styles={styles.pagination}
              onChange={onPageChange}
            />
          )}
        </Device>
      </>
        :
        <BaseItem
          title="Keine Ergebnisse"
          text="Bitte passen Sie ihr Filter erneut an"
          styles={styles.emptyResults}
        />
      }
    </div>
  )
}

export default compose(
  withQueryWrapper,
  applyStyling(baseStyles)
)(AnimalsList)
