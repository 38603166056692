// @flow

import React, { useMemo } from 'react'
import { applyStyling } from '../../lib/styling'
import Gallery from '../basic/Gallery/index'
import Modal from '../basic/Modal/index'

import baseStyles from './AnimalGallery.scss'

type Props = {
  styles: Object,
  title?: string,
  photos: Array<Object>,
  visible: Boolean,
  startIndex?: number,
  closeModal: Function
}

const AnimalGallery = ({ styles, title, photos, visible, closeModal, startIndex = 0 }: Props) => {
  const items = useMemo(() => (photos || []).map(photo => {
    return ({
      original: photo.original || photo,
      thumbnail: photo.thumbnail || photo
    })
  }), [ photos ])

  return (
    <Modal
      title={<span dangerouslySetInnerHTML={{ __html: title }}/>}
      visible={visible}
      closeButton
      closeOnOverlayClick={true}
      onClose={closeModal}
      styles={styles.modal}
    >
      <Gallery
        items={items}
        showNav
        showThumbNav
        showThumbnails
        startIndex={startIndex}
        styles={styles.gallery}
        lazyLoad
      />
    </Modal>
  )
}

export default applyStyling(baseStyles)(AnimalGallery)
