// @flow
import React, { Component } from 'react'
import { withQuery, defaultValues } from '../../newsSearch'
import WithHandlersWrapper, { type WithNewsSearchHandlersProps }
  from '../../components/NewsSearch/WithHandlersWrapper'
import type { WithQueryProps } from '../../index'
import _ from 'lodash'

export type WithNewsSearchQueryWrapperProps = WithQueryProps & WithNewsSearchHandlersProps & {
  onResetFilters: Function
}

const withQueryWrapper = (InnerComponent: any) => {
  class Wrapper extends Component<*> {
    onResetFilters = () => {
      this.props.reset()
    }

    onPartialResetFilters = (keepKeys = []) => {
      this.props.setStateValue({ ...defaultValues, ..._.pick(this.props.values, keepKeys) })
    }

    setSearchValue = (value: Object) => {
      this.props.setStateValue({ ...this.props.values, ...value })
    }

    render() {
      const { values, updateStateValue, ...rest } = this.props
      return (
        <WithHandlersWrapper newsSearch={values} updateStateValue={updateStateValue}>
          {props => (
            <InnerComponent
              {...props}
              {...rest}
              updateStateValue={updateStateValue}
              setSearchValue={this.setSearchValue}
              onResetFilters={this.onResetFilters}
              onPartialResetFilters={this.onPartialResetFilters}
            />
          )}
        </WithHandlersWrapper>
      )
    }
  }

  return withQuery(Wrapper)
}

export default withQueryWrapper
