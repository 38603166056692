// @flow

/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { applyStyling } from '../../lib/styling'
import Device from '../basic/Device'
import { Container, Row, Col } from '../../components/basic/Grid'
import HeroTeaser from '../../components/HeroTeaser'
import { withPostList } from '../../provider/post'
import Search from '../Search'
import Pagination from '../../components/basic/Pagination'
import { compose, withProps } from 'recompose'
import withQueryWrapper from '../../state/enchancers/globalSearch/withQueryWrapper'
import { withSearch } from '../../enhancers/withGlobalSearch'
import SearchResultItem from './components/SearchResultItem'
import SearchStat from './components/SearchStat'
import { scrollToTarget } from 'helpers/scroll'
import classNames from 'classnames'

import baseStyles from './SiteSearchResultsPage.scss'


type Props = {
  styles: Object,
  imageLandscape: string,
  imageLandscape2x: string,
  imageLandscape3x: string,
  imageLandscape4x: string,
  content: string,
  heroTeaserTitle: string,
  heroTeaserSubtitle: string,
  onPageChange: Function,
  onQueryChange: Function,
  createQueryParams: Function,
  globalSearch: Object,
  isLoading: Boolean,
  posts: Object
}

const SiteSearchResultsPage = ({
  onPageChange,
  styles,
  imageLandscape,
  imageLandscape2x,
  imageLandscape3x,
  imageLandscape4x,
  heroTeaserTitle,
  heroTeaserSubtitle,
  globalSearch, posts, isLoading, content, onQueryChange, createQueryParams
}: Props) => {
  const SearchComponent = (({ ...rest }: Object) => (
    <Search
      onQueryChange={onQueryChange}
      createQueryParams={createQueryParams}
      styles={styles.search}
      searchValues={globalSearch}
      targetPage="suche"
      {...rest}
    />
  ))

  useEffect(() => {
    scrollToTarget(0)
  }, [ globalSearch.page ])

  return (
    <div className={classNames(styles.root, isLoading ? styles.isLoading : '')}>
      <HeroTeaser
        imageLandscape={imageLandscape}
        imageLandscape2x={imageLandscape2x}
        imageLandscape3x={imageLandscape3x}
        imageLandscape4x={imageLandscape4x}
        title={heroTeaserTitle}
        subtitle={heroTeaserSubtitle}
        styles={styles.teaser}
      />

      <Container className={styles.container}>
        <div dangerouslySetInnerHTML={{ __html: content }}/>

        <SearchComponent/>

        {posts && posts.total > 0 &&
        <Row className={styles.row}>
          <Col md="12" className={styles.col}>
            <SearchStat
              total={posts.total} page={globalSearch.page} perPage={globalSearch.perPage} query={globalSearch.query}
            />
          </Col>
        </Row>
        }

        <Row className={styles.row}>
          {posts && posts.result.map(item => (
            <Col md="12" className={styles.col} key={item.id}>
              <SearchResultItem item={item}/>
            </Col>
          ))}
        </Row>
        {posts && posts.total === 0 &&
          <div>
            <h2>Keine Ergebnisse</h2>
            <p>Bitte passen Sie ihr Filter erneut an</p>
          </div>
        }

        {posts && posts.total > globalSearch.perPage && <Device tablet>
          {matches => (
            <Pagination
              previousLabel="Zurück"
              nextLabel="Weiter"
              initialSelected={globalSearch.page || 0}
              results={posts.total}
              resultsPerPage={globalSearch.perPage}
              pageRangeDisplayed={matches ? 1 : 3}
              marginPagesDisplayed={1}
              styles={styles.pagination}
              onChange={onPageChange}
            />
          )}
        </Device>}
      </Container>
    </div>
  )
}

export default compose(
  withSearch,
  withQueryWrapper,
  withProps(({ globalSearch }) => ({
    search: globalSearch// ,
    // skip: !globalSearch?.query
  })),
  withPostList([ 'news', 'articles', 'projects', 'team', 'volunteers', 'lost_pet', 'private_pet', 'animal_data' ]),
  applyStyling(baseStyles)
)(SiteSearchResultsPage)
