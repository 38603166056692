// @flow

/* eslint-disable max-len */
import React, { useRef, useCallback } from 'react'
import { applyStyling } from '../../lib/styling'
import classNames from 'classnames'
import { scrollToTarget } from '../../helpers/scroll'
import { Container } from '../basic/Grid'
import BackBtn from '../BackBtn'
import Share from '../Share'
import Details from './Details'
import ContactForm from '../ContactForm'
import Device from '../basic/Device'
import baseStyles from './AnimalDetailsPage.scss'

type Props = {
  styles: Object,
  item: Object,
  isLoading: boolean,
  isLostPet?: boolean
}

const AnimalDetailsPage = (props: Props) => {
  const {
    styles, item, isLostPet, isLoading
  } = props
  const contactRef = useRef(null)
  const scrollToContact = useCallback(() => {
    if (contactRef && contactRef.current) {
      scrollToTarget(contactRef.current.offsetTop)
    }
  })

  return (
    <div className={classNames(styles.root, isLoading ? styles.loading : '')}>
      {item &&
      <Container>
        <div className={styles.toolbar}>
          <BackBtn />
          <Device phone>
            <Share />
          </Device>
        </div>

        <div className={styles.panel}>
          <Details
            isLostPet={isLostPet}
            data={item}
            scrollToContact={scrollToContact}
          />
        </div>

        <Device tablet desktop>
          <div className={styles.panel}>
            <div className={styles.body} ref={contactRef}>
              <ContactForm title="Kontakt" animalType={item.type} />
            </div>
          </div>
        </Device>
      </Container>}
    </div>
  )
}

export default applyStyling(baseStyles)(AnimalDetailsPage)
